product-group-detail {
    overflow-x: hidden;
    padding: 0 15px 150px;
    width: 100%;

    @media screen and (max-width: 800px) {
        margin-top: -5px;
    }

    @media screen and (min-width: 1199px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width: 1680px) {
        padding: 0 calc((100vw - 1600px) / 2) 100px;
    }
}

.product_group_detail__back_btn {
    align-items: center;
    display: inline-flex;
    font-size: 12px;
    left: -8px;
    margin-bottom: 32px;
    position: relative;

    @media screen and (max-width: 1199px) {
        margin-bottom: 24px;
        margin-top: 4px;
    }

    @media screen and (max-width: 800px) {
        display: none;
    }

    &:hover {
        color: #000;
    }

    &_icon {
        height: 9px;
        transform: rotateZ(90deg);
        width: 22px;

        & path {
            stroke-width: 8px;
            stroke: currentColor;
        }
    }

    &_text {
        cursor: pointer;
    }
}

.product_group_detail__container {
    contain: content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.product_group_detail__gallery {
    aspect-ratio: 1 / 0.75;
    display: flex;
    flex: 0 0 50%;
    width: 100%;
    max-height: 733px;

    @media screen and (max-width: 800px) {
        flex: 0 0 auto;
        height: 100vw;
        max-height: 35vh;
        margin-bottom: 20px;
    }
}

.product_group_detail__gallery_thumbs {
    -ms-overflow-style: none;
    flex: 0 0 16.66667%;
    height: 100%;
    overflow: scroll;
    scrollbar-width: none;
    user-select: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 960px) {
        display: none;
    }

    &_item {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        border: 2px solid transparent;
        cursor: pointer;
        margin: 6px 6px 6px 0px;
        position: relative;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--active {
            border: 2px solid #7493a6;
        }
    }

    &_item_image {
        aspect-ratio: 1 / 1.5;
        mix-blend-mode: multiply;
        object-fit: contain;
        object-position: center;
        width: 100%;
    }
}

.product_group_detail__gallery_pagination {
    align-items: center;
    bottom: -30px;
    display: none;
    gap: 8px;
    height: 30px;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 100%;

    @media screen and (max-width: 800px) {
        display: flex;
    }

    &_item {
        background-color: #ccc;
        border-radius: 100%;
        cursor: pointer;
        height: 10px;
        width: 10px;

        &--active {
            background-color: #00bfff;
        }
    }
}

.product_group_detail__gallery_ext_link {
    align-items: center;
    background: #0000;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    display: none;
    height: 2.5em;
    justify-content: center;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    width: 2.5em;
    z-index: 1;

    @media screen and (max-width: 800px) {
        display: flex;
    }

    & svg {
        fill: #00bfff;
        height: 2.5em;
        width: 2.5em;
    }
}

.product_group_detail__gallery_main {
    background-color: rgba(0, 0, 0, 0.05);
    flex: 0 0 83.33333%;
    height: 100%;
    position: relative;
    user-select: none;
    border-radius: 8px;

    @media screen and (max-width: 960px) {
        flex: 0 0 100%;
        width: 100%;
    }

    &_arrow {
        cursor: pointer;
        height: 100%;
        position: absolute;
        top: 0;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        &--hidden {
            display: none;
        }

        &_icon {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 100%;
            color: #fff;
            height: 52px;
            padding: 12px;
            width: 52px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.15);
            }

            & path {
                stroke-width: 8px;
                stroke: currentColor;
            }

            &--prev {
                transform: rotateZ(90deg);
                padding-bottom: 6px;
            }

            &--next {
                transform: rotateZ(-90deg);
                padding-bottom: 6px;
            }
        }
    }

    &_link {
        display: block;
        height: 100%;
        width: 100%;
    }

    &_image {
        cursor: zoom-in;
        height: 100%;
        object-fit: contain;
        object-position: center;
        width: 100%;
        padding: 2%;
    }
}

.product_group_detail__info {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    font-size: 14px;
    line-height: 1.2;
    padding-left: 6%;
    position: relative;
    width: 50%;

    @media screen and (max-width: 800px) {
        flex: 0 0 auto;
        padding: 22px 0 0;
        width: 100%;
    }

    @media screen and (max-width: 450px) {
        font-size: 12px;
    }

    & .product__like {
        top: -17px;
        right: -15px;

        @media screen and (max-width: 1199px) {
            right: -14px;
        }

        @media screen and (max-width: 800px) {
            top: 4px;
            right: -14px;
        }

        @media screen and (max-width: 450px) {
            top: 8px;
            right: -8px;
        }
    }

    &_brand {
        font-size: 18px;
        font-weight: 700;
        max-width: calc(100% - 54px);
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;

        @media screen and (max-width: 800px) {
            font-size: 16px;
        }
    }

    &_title {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        font-size: 18px;
        line-clamp: 1;
        margin: 0 0 16px;
        overflow: hidden;
        padding-right: 24px;

        @media screen and (max-width: 800px) {
            font-size: 16px;
        }
    }

    &_headline {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 10px;
        color: #595959;

        @media screen and (max-width: 800px) {
            font-size: 16px;
            margin-top: 0;
        }
    }

    &_description {
        &_text {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            display: -webkit-box;
            line-clamp: 4;
            line-height: 1.65;
            overflow: hidden;
            text-align: justify;
            word-break: break-all;

            &--no-line-clamp {
                -webkit-line-clamp: none;
                line-clamp: none;
                display: block;
            }

            @media screen and (max-width: 800px) {
                -webkit-line-clamp: 2;
                line-clamp: 2;

                &--no-line-clamp {
                    -webkit-line-clamp: none;
                    line-clamp: none;
                    display: block;
                }
            }
        }

        &_more {
            align-items: end;
            color: #9f9f9f;
            cursor: pointer;
            display: none;
            font-size: 14px;
            font-weight: 700;

            &--visible {
                display: flex;
            }

            &_icon {
                height: 17px;
                transform: rotateZ(90deg);
                width: 17px;
            }
        }
    }

    &_offer {
        align-items: center;
        display: flex;
        font-size: 24px;
        margin-top: 38px;

        @media screen and (max-width: 450px) {
            margin-top: 30px;
            font-size: 18px;
        }

        &_image {
            border-radius: 4px;
            border: 1px solid #595959;
            height: 34px;
            object-fit: contain;
            padding: 2px 4px;
            width: 120px;
        }

        &_text {
            font-size: 14px;
            font-weight: 700;
        }

        &_price {
            margin-left: auto;

            &--new-price {
                color: #cc3333;
                font-weight: 700;
            }

            &--old-price {
                font-size: 18px;
                font-weight: 300;
                margin-left: 6px;
                text-decoration: line-through;
            }
        }
    }

    &_cta {
        background-color: #f9564e;
        border-radius: 4px;
        color: #fff;
        margin-top: 12px;
        padding: 12px 0;
        text-align: center;
        width: 100%;

        &:hover {
            background-color: #fd2e23;
            cursor: pointer;
        }

        &_text {
            font-size: 18px;
            font-weight: 700;
            user-select: none;
        }
    }

    &_sizes {
        width: 100%;

        &_title {
            font-weight: 700;
            margin-bottom: 4px;
            margin-top: 22px;
        }

        &_list {
            display: flex;
            width: 100%;
            overflow-x: auto;

            &_link {
                margin: 5px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            &_item {
                border-radius: 4px;
                border: 1px solid #9f9f9f;
                flex: 0 0 auto;
                min-width: 34px;
                padding: 5px 7px;
                text-align: center;

                &:hover {
                    background-color: #f5f5f5;
                    cursor: pointer;
                }
            }
        }
    }

    &_list {
        display: table;
        margin-top: 22px;

        @media screen and (max-width: 800px) {
            display: none;
        }

        &_row {
            display: table-row;

            &_title {
                color: #9f9f9f;
                display: table-cell;
                font-weight: 700;
                padding: 4px 26px 4px 0;
                width: 30%;
                white-space: nowrap;
            }

            &_value {
                display: table-cell;
                padding: 4px 0;
                width: 70%;
            }
        }
    }
}

.offer__container {
    display: flex;
    flex-flow: column;
    gap: 16px;
    padding: 6px 0 16px;
}

.offer__item {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    margin: 0 3px;
    padding: 0;
}

.offer__item_headline {
    display: flex;
    justify-content: space-between;

    &_badge {
        align-items: center;
        background-color: #4bc736;
        border-bottom-left-radius: 12px;
        border-top-right-radius: 15px;
        color: #fff;
        display: flex;
        font-size: 12px;
        font-weight: 700;
        height: 27px;
        margin-right: -1px;
        margin-top: -1px;
        padding: 0 10px 0 15px;
    }
}

.offer__item_shop_logo {
    align-items: center;
    box-sizing: content-box;
    display: flex;
    height: 45px;
    object-fit: contain;
    padding: 7px 15px;
    width: 90px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    hyphens: auto;

    @media screen and (max-width: 800px) {
        width: 70px;
        height: 35px;
    }
}

.offer__item_divider {
    border-bottom: 1px solid #f5f5f5;
    margin: 0 10px 10px;
}

.offer__item_price_container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px 7px;
}

.offer__item_price {
    font-size: 16px;
    font-weight: 700;
    word-spacing: -2px;

    &--old {
        color: #7c807e;
        font-size: 14px;
        font-weight: 500;
        margin-right: 6px;
        position: relative;
        word-spacing: -2px;

        &::before {
            background-color: #7c807e;
            content: '';
            height: 1px;
            position: absolute;
            top: 50%;
            width: 100%;
        }
    }

    &--base {
        opacity: 0.8;
        font-size: 12px;
        font-weight: 500;
        margin-left: 6px;
        word-spacing: -2px;
    }

}

.offer__item_cta {
    align-items: center;
    background-color: #fa5762;
    border-radius: 30px;
    box-shadow: 1px 2px 3px hsla(0, 0%, 79.6%, .8509803922);
    color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 10px;
    padding: 6px 5px 6px 14px;
    transition: .2s;
    white-space: nowrap;

    &:hover {
        transform: scale(1.05);
    }

    &_icon {
        height: 14px;
        margin-left: 0px;
        width: 14px;
        line-height: 14px;
    }
}

.offer__item_shop_meta {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0 0 10px;
    color: #737373;

    &_item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #eee;
        line-height: 1.6;

        &:first-child {
            border-left: none;
        }
    }

    &_icon {
        width: 16px;
        height: 16px;
        margin: 0 4px;
    }

    &_text {
        font-weight: 500;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        text-align: right;
        margin-right: 5px;
        overflow: hidden;
    }
}

.product_group_detail__recommendations {
    &_title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 12px;

        @media screen and (max-width: 800px) {
            font-size: 16px;
            margin-bottom: 0;
        }
    }

    &_container {
        contain: content;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        padding-top: 15px;
    }
}