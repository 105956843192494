.wishlist__headline_container {
    align-items: center;
    background: linear-gradient(260deg, #ff9387 0%, #febba0 100%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    padding: 10vw 0;
    margin-top: -20px;

    @media screen and (orientation: landscape) {
        padding: 3vw 0;
    }

    @media screen and (min-width: 1199px) {
        margin-top: -40px;
    }
}
.wishlist__headline_background {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    opacity: 0.2;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
}
.wishlist__headline_text {
	color: white;
	font-family: "Lobster";
	font-size: 8vw;
    z-index: 1;
    margin: 0;

    @media screen and (orientation: landscape) {
        font-size: 4vw;
    }
}
.wishlist__product_container {
    contain: content;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 40px;
}
.wishlist__product_container_text {
    font-size: 14px;
    line-height: 1.67;
    max-width: 65ch;
    margin: 0 auto;
}