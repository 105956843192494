.recommendations__container {
    display: flex;
    flex-direction: column;
}

.recommendations__headline__text {
    color: #595959;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;

    @media screen and (min-width: 600px) {
        font-size: 20px;
    }
}