.filter__item-container {
  border-radius: 4px;
  border: 1px solid #9f9f9f;
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 14px;
  margin-right: 16px;
  position: relative;
  width: 144px;

  &--hidden {
    display: none;
  }
}

.filter__title {
  align-items: center;
  display: flex;
  padding: 7px 12px;

  &:hover {
    color: #595959;
    cursor: pointer;
  }

  .filter__item-container[open] & {
    color: #595959;
  }
}

.filter__title-text {
  margin-right: 4px;
  user-select: none;
}

.filter__title-active-count {
	align-items: center;
	background-color: #595959;
	border-radius: 6px;
	color: #fff;
	font-size: 10px;
	height: 16px;
	justify-content: center;
	padding: 0 4px;
	user-select: none;
  display: none;
  min-width: 16px;

  &--visible {
    display: flex;
  }
}

.filter__title-icon {
  height: 20px;
  margin-left: auto;
  position: relative;
  top: 1px;
  transform: rotateZ(90deg);
  width: 20px;
}

.filter__content-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: none;
  flex-direction: column;
  left: 0px;
  margin-top: 5px;
  min-width: 280px;
  position: absolute;
  word-break: break-word;
  z-index: 1;

  .filter__item-container[open] & {
    display: flex;
  }

  &--align-right {
    right: 0px;
    left: unset;
  }
}

.filter__content {
  flex: 1 1 auto;
  max-height: 360px;
  overflow: hidden auto;
  padding: 0.3rem 0px;
}

.filter__item-heading {
  padding-left: 8px;
  margin-top: 10px;

  &:first-child {
    margin-top: 6px;
  }
}

.filter__item-heading-text {
  text-decoration: underline;
}

.filter__item {
	color: #9f9f9f;
	line-height: 2.5em;
	padding-left: 36px;
  align-items: center;
  display: flex;
  position: relative;

  &:hover {
    color: #595959;
    cursor: pointer;
  }

  &[data-option-active="true"] {
    color: #595959;
  }
}

.filter__item-select {
	align-items: center;
	display: flex;
	height: 20px;
	justify-content: center;
	left: 8px;
	padding: 3px;
	position: absolute;
	top: 8px;
	width: 20px;
}

.filter__item-select-icon {
	height: 10px;
	width: 10px;
  display: none;

  .filter__item[data-option-active="true"] & {
    display: block;
    flex: 0 0 10px;
  }
}

.filter__item-link {
  color: inherit;
  text-decoration: none;

  &:hover, &:focus, &:active, &:visited {
    color: inherit;
    text-decoration: none;
  }
}

.filter__item-text {
  cursor: pointer;
  user-select: none;
}

.filter__item-count {
	flex: 0 0 auto;
	font-size: 10px;
	padding-left: 8px;
	text-align: center;
}

.filter__content-btn-container {
	align-items: center;
	border-top: 1px solid #e9e9e9;
	display: flex;
	flex: 0 0 44px;
	justify-content: center;
}

.filter__content-btn {
  align-items: center;
  display: flex;
  flex: 0 0 50%;
  height: 100%;
  justify-content: center;
  color: #595959;
  opacity: 0.4;

  &--save {
    background-color: #595959;
    color: #fff;
  }

  &--visible {
    cursor: pointer;
    opacity: 1;
  }
}

.filter__content-btn-text {
  user-select: none;
}

.filter__content-btn-icon {
  margin-right: 4px;
  stroke-width: 2px;
  width: 14px;
}

.filter__clear-filter {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap: 4px;
  height: 34px;
  justify-content: center;
  line-height: 20px;
  margin: 0 10px;

  &-icon {
    height: 16px;
    width: 16px;
  }
}