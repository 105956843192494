.filter__price_wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 14px 18px 0;
    width: 100%;
  }
  .filter__price_input {
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 1px solid #e9e9e9;
    box-shadow: none;
    box-sizing: content-box;
    display: flex;
    height: 30px;
    padding: 0 5px;
    transition: border 0.2s ease-in-out;
    width: 80px;
  
    &:focus-within, &:hover {
      border: 1px solid #f9564e;
    }
  
    &_text {
      -moz-appearance: textfield;
      -webkit-appearance: textfield;
      -webkit-border-radius: none;
      background-color: inherit;
      border-radius: none;
      border: none;
      color: #9f9f9f;
      flex: 1 1 auto;
      font-weight: 700;
      margin: 0;
      outline: none;
      text-align: center;
      text-decoration: none;
      width: 40px;
  
      &:focus, &:hover {
        border: none;
        box-shadow: none;
        color: #595959;
        outline: none;
      }
    }
  
    &_currency {
    }
  
    &_divider {
      background-color: #9f9f9f;
      height: 1px;
      width: 10px;
    }
  }
  .filter__price_slider_wrapper {
    margin: 24px 10px 22px;
    padding: 0 18px;
  }
  .filter__price_slider {
    -ms-touch-action: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    background: #e8e8e8;
    direction: ltr;
    height: 2px;
    position: relative;
    touch-action: none;
    user-select: none;
  }
  .filter__price_slider__base,
  .filter__price_slider__connects {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .filter__price_slider__connects {
    overflow: hidden;
    z-index: 0;
  }
  .filter__price_slider__connect,
  .filter__price_slider__origin {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0 0;
    width: 100%;
    will-change: transform;
    z-index: 1;
  }
  .filter__price_slider__connect {
    background: #a1a1a1;
  }
  .filter__price_slider__origin {
    height: 0;
    width: 10%;
  }
  .filter__price_slider__handle {
    background: #fff;
    border-radius: 50%;
    border: 1px solid #595959;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: ew-resize;
    height: 20px;
    left: auto;
    position: absolute;
    right: -10px;
    top: 1px;
    transform: translateY(-50%);
    width: 20px;
  }
  .filter__price_slider__drag * {
    cursor: inherit !important;
  }
  .filter__price_slider__touch-area {
    height: 100%;
    width: 100;
  }
  .filter__filter_checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 42px;
    padding: 0 16px;
  
    &[available="False"] {
      cursor: not-allowed;
      opacity: 0.15;
    }
  
    &_icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='%239f9f9f' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='2' /%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 22px;
      margin-right: 2px;
      stroke: #999;
      width: 22px;
    }
  
    &[checked="true"] &_icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23f9564e' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='2' /%3E%3Cpath d='M9 12l2 2l4 -4' /%3E%3C/svg%3E");
    }
  
    &_text {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;

      &:hover {
        color: #595959;
      }
    }
  }