.mobile_filter__filter_items_search {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 0 0 auto;
  padding-bottom: 10px;
  position: relative;

  mobile-categorical-filter[has-search-bar="false"] & {
    display: none;
  }
}
.mobile_filter__filter_items_search_input_wrapper {
  flex: 1 1 auto;
  height: 42px;
  margin-left: 8px;
  padding-right: 30px;
  position: relative;

  &:after {
    background-color: #666;
    bottom: 2px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &:focus-within:after {
    background-color: #f9564e;
  }
}
.mobile_filter__filter_items_search_input {
  border: none;
  color: inherit;
  height: 100%;
  margin: 0;
  outline: none !important;
  padding: 0;
  width: 100%;

  &:focus {
    color: inherit;
    border: none;
  }
}
.mobile_filter__filter_option_container {
  contain: content;
  flex: 1 1 auto;
  overflow-y: scroll;

  &--hide {
    display: none;
  }
}
.mobile_filter__filter_option {
  contain: strict;
  cursor: pointer;
  display: flex;
  height: 42px;
  padding: 10px 0;
}
.mobile_filter__filter_option_link {
  color: #595959;
}
.mobile_filter__filter_option_text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  & em {
    font-style: initial;
    font-weight: 700;
  }

  .mobile_filter__filter_option[data-active="true"] & {
    font-weight: 700;
  }
}
.mobile_filter__filter_option_icon {
  visibility: hidden;

  .mobile_filter__filter_option[data-active="true"] & {
    visibility: visible;
  }
}
