.mobile_filter {
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin: 0 -15px 30px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    display: none;
  }
}
.mobile_filter__button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: white;
  contain: content;
  display: flex;
  flex: 0 1 50%;
  justify-content: center;
  margin: 0 15px;
  padding: 11px 0;
  transition: none;
}
.mobile_filter__icon {
  display: none;
  height: auto;
  stroke: #fff;
  width: 22px;
  stroke-width: 6;

  &--visible {
    display: initial;
  }

  &--menu-item {
    stroke: #666;
    stroke-width: 2;

    & circle {
      stroke-width: 6;
    }

    & text {
      stroke: #fff;
    }
  }
}
.mobile_filter__button_text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  font-size: 14px;
  font-weight: 700;
  margin-left: 6px;
  user-select: none;
}
.mobile_filter__filter_container {
  -moz-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  contain: strict;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  max-width: 500px;
  overflow: hidden;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition: 0.3s ease-in-out;
  user-select: none;
  width: 100%;
  will-change: transform;
  z-index: 6;

  &--active {
    transform: translate3d(0, 0, 0);
  }
}
.mobile_filter__inner_filter_container {
  background-color: #fff;
  flex: 1 1 700px;
  max-height: 100%;
  max-width: 500px;
  position: relative;
  width: 100%;
}
.mobile_filter__menu {
  background-color: white;
  contain: strict;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s ease-in-out;
  width: 100%;
  will-change: transform;

  &--sub {
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }

  &[submenu-active="true"] {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
.mobile_filter__filter_headline_container {
  align-items: center;
  border-bottom: 1px solid #666;
  display: flex;
  flex: 0 0 51px;
  font-size: 18px;
  font-weight: 900;
  justify-content: flex-end;
  padding: 5px 5px 5px 15px;
  text-align: center;
}
.mobile_filter__filter_headline_text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  flex: 1 1 auto;
  padding-right: 10px;
  user-select: none;
}
.mobile_filter__filter_headline_action_text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  cursor: pointer;
  display: none;
  font-size: 14px;
  position: absolute;
  right: 15px;
  user-select: none;

  .mobile_filter__menu[has-active-filters="true"] &,
  .mobile_filter__menu[has-active-sorting="true"] & {
    display: initial;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #f9564e;
  }
}
.mobile_filter__filter_container_icon {
  border: 10px solid transparent;
  box-sizing: content-box;
  flex: 0 1 20px;
  height: 20px;
  stroke: #666;
  width: 20px;

  &--chevron {
    transform: rotate(-90deg);
  }

  &--search-delete {
    cursor: pointer;
    position: absolute;
    right: -14px;
    stroke: #666;
    visibility: hidden;

    .mobile_filter__filter_items_search[has-focus="true"] & {
      visibility: visible;
    }
  }
}
.mobile_filter__filter_items_container {
  flex: 1 1 auto;
  overflow: scroll;

  &--submenu {
    display: flex;
    flex-flow: column;
    padding: 15px;
  }
}
.mobile_filter__filter_item {
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  font-size: 18px;
  font-weight: 900;
  padding: 5px 0 5px 15px;

  &_text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    flex: 1 1 auto;
    user-select: none;
  }
}
.mobile_filter__filter_items_icon {
  height: 22px;
  stroke: #333;
  width: 22px;

  &--check {
    stroke: #f9564e;
    flex: 0 0 22px;
  }
}
.mobile_filter__filter_checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 42px;

  &[available="False"] {
    cursor: not-allowed;
    opacity: 0.15;
  }

  &_icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='%239f9f9f' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='2' /%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 22px;
    margin-right: 8px;
    stroke: #999;
    width: 22px;
  }

  &[checked="true"] &_icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23f9564e' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='2' /%3E%3Cpath d='M9 12l2 2l4 -4' /%3E%3C/svg%3E");
  }

  &_text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
}
.mobile_filter__filter_actions_container {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  flex: 0 0 auto;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
  text-align: center;
}
.mobile_filter__filter_action {
  align-items: center;
  border-radius: 2px;
  border: 1px solid #fff;
  cursor: pointer;
  display: none;
  padding: 10px 0;
  position: relative;

  &--active {
    display: flex;
  }

  &_text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    flex: 1 1 auto;
    user-select: none;
  }

  &_icon {
    border: 10px solid transparent;
    box-sizing: content-box;
    height: 20px;
    left: 0;
    position: absolute;
    stroke: #fff;
    width: 20px;

    &--chevron-back {
      transform: rotate(90deg);
    }
  }
}
