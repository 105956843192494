.sorting {
    align-items: center;
    color: #9f9f9f;
    display: none;
    flex: 0 0 max-content;
    font-size: 14px;
    font-weight: 700;
    position: relative;

    @media screen and (min-width: 1200px) {
        display: flex;
    }
}
.sorting__pre-text {
    margin-right: 12px;
}
.sorting__icon {
	bottom: 1px;
	height: 16px;
	margin-right: 2px;
	position: relative;
	stroke-width: 10px;
	stroke: #9f9f9f;
	width: 16px;

    .sorting[open] & {
        stroke: #595959;
    }
}
.sorting__title {
    align-items: center;
    border-radius: 4px;
    border: 1px solid #9f9f9f;
    display: flex;
    padding: 7px 12px;
  
    &:hover {
        border-color: #595959;
        color: #595959;
        cursor: pointer;

        .sorting__icon {
            stroke: #595959;
        }
    }
  
    .sorting[open] & {
        color: #595959;
        border-color: #595959;
    }
  }
  
.sorting__title-text {
    margin-right: 20px;
    user-select: none;
}
.sorting__title-icon {
    height: 20px;
    margin-left: auto;
    position: relative;
    top: 1px;
    transform: rotateZ(90deg);
    width: 20px;
}
.sorting__content-container {
    background-color: white;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    cursor: pointer;
    display: none;
    flex-flow: column;
    gap: 12px;
    margin-top: 5px;
    padding: 12px 16px;
    position: absolute;
    right: 0px;
    top: 34px;
    z-index: 3;

    .sorting[open] & {
        display: flex;
    }
}
.sorting__item {
    &--active, &:hover {
        color: #595959;
    }
}