.hscroll__container {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
.hscroll__cursor {
    background-color: hsl(0, 0%, 99%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
    bottom: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.1s;
    width: 40px;
    z-index: 1;
}
@media screen and (max-width: 600px) {
    .hscroll__cursor {
        width: 20px;
    }
}
.hscroll__cursor:hover {
    cursor: pointer;
}
.hscroll__cursor--visible {
    opacity: 1;
}
.hscroll__cursor--left {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsPSJub25lIiBkPSJNLTEtMWg1ODJ2NDAySC0xeiIvPgogIDxnPgogICAgPHBhdGggZmlsbD0iIzZiNmI2YiIgZD0iTTM1Mi4yODkzNjAyOSAzOTYuNDAxOTkxMDdsLTIwLjY2MDAwMjA1IDE5LjQ0MjU5MDIzLTE3MS42MjkxNTQ2NS0xNTkuNjQ2ODcwNTRMMzMwLjk3MDMyMjM4IDk1Ljg0NTI1OTk0bDIwLjczOTcwNDIgMTkuMjU3MzI3Ny0xNTAuMjA5OTExIDE0MS4wMDk2NTQ0eiIvPgogIDwvZz4KPC9zdmc+Cg==");
    left: -12px;
}
@media screen and (max-width: 600px) {
    .hscroll__cursor--left {
        left: -6px;
    }
}
.hscroll__cursor--right {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsPSJub25lIiBkPSJNLTEtMWg1ODJ2NDAySC0xeiIvPgogIDxnPgogICAgPHBhdGggZmlsbD0iIzZiNmI2YiIgZD0iTTE2MCAxMTUuNEwxODAuNyA5NiAzNTIgMjU2IDE4MC43IDQxNiAxNjAgMzk2LjcgMzEwLjUgMjU2eiIvPgogIDwvZz4KPC9zdmc+Cg==");
    right: -10px;
}
@media screen and (max-width: 600px) {
    .hscroll__cursor--right {
        right: -6px;
    }
}
.hscroll__cursor--left.hscroll__cursor--visible::before {
    background-image: linear-gradient(
        to left,
        hsla(0, 0%, 99%, 0),
        hsla(0, 0%, 99%, 1)
    );
    content: "";
    height: 100%;
    position: absolute;
    right: -40px;
    width: 40px;
    z-index: 1;
}
@media screen and (max-width: 600px) {
    .hscroll__cursor--left.hscroll__cursor--visible::before {
        right: -10px;
        width: 10px;
    }
}
.hscroll__cursor--right.hscroll__cursor--visible::before {
    background-image: linear-gradient(
        to right,
        hsla(0, 0%, 99%, 0),
        hsla(0, 0%, 99%, 1)
    );
    content: "";
    height: 100%;
    left: -40px;
    position: absolute;
    width: 40px;
    z-index: 1;
}
@media screen and (max-width: 600px) {
    .hscroll__cursor--right.hscroll__cursor--visible::before {
        left: -10px;
        width: 10px;
    }
}

.nav-assistant__heading {
    color: #595959;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}
.nav-assistant__container {
    position: relative;
    display: flex;
    overflow: auto;
    margin: 0 0 25px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;

    @media screen and (max-width: 1199px) {
        margin: 0 0 22px;
    }
  }
  .nav-assistant__container::-webkit-scrollbar {
    display: none;
  }
  
  .nav-assistant__list {
    display: flex;
    overflow: auto;
  }
  
  .nav-assistant__link {
    background-color: #fff;
    margin: 1px 6px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    border: 1px solid transparent;
    flex: 0 0 auto;
    height: 60px;
    padding: 6px;
    width: 110px;
    cursor: pointer;

    @media screen and (max-width: 600px) {
        height: 50px;
        width: 100px;
    }

    &:hover, &--active {
        border: 1px solid rgb(239, 119, 0);
    }

    .nav-assistant__container--categories & {
        border-radius: 50px;
        height: 36px;
        width: auto;
        padding: 6px 14px;
        box-shadow: none;
        border: 1px solid rgb(89, 89, 89);

        &:hover, &--active {
            border: 1px solid rgb(239, 119, 0);
        }
    }
  }
  .nav-assistant__link:first-of-type {
    margin-left: 1px;
  }
  
  .nav-assistant__image {
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
  
  .nav-assistant__text {
    align-items: center;
    display: flex;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    line-height: 1.2;
    text-align: center;
    user-select: none;
    width: 100%;

    &--brand {
      text-transform: uppercase;
    }
  }