#page__coupons {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.modal-open {
    overflow: hidden;
    max-height: 100vh;
}

.container--coupons {
    width: 100%;
}

.coupons_headline {
    display: flex;
    margin-bottom: 1rem;
    gap: 18px;
}

.coupons_headline__image {
    height: 60px;
    object-fit: contain;
    width: 60px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-radius: 4px;
    padding: 4px;
    background-color: #fff;
}

.coupons_headline__text {
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
}

.coupons__subheadline {
    margin: 0;
}

.coupons__subheadline__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
}

.coupon__list {
    display: flex;
    flex-flow: column;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 14px;
}

.coupon__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    position: relative;
}

.coupon__item__header {
    display: flex;
    flex-flow: column;
}

.coupon__badges {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}

.coupon__badges__item {
    background-color: #f5f5f5;
    border-radius: 4px;
    color: #5f6368;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.25;
    margin: 0;
    padding: 3px 5px;
    text-transform: uppercase;

    &--coupon {
        background-color: #4caf50;
        color: #fff;
    }
}

.coupon__item__header__headline {
    font-size: 16px;
    line-height: 1.25;
    margin: 0;
}

.coupon__item__header__headline__text {
    color: #333;
    font-size: 16px;
    margin: 0;
}

.coupon__urgency {
    background-color: #333;
    position: absolute;
    right: -6px;
    top: -10px;

    &::after {
        background-color: #333;
        bottom: -5px;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        content: "";
        height: 5px;
        position: absolute;
        right: 0;
        width: 5px;
    }
}

.coupon__urgency__text {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin: 0 10px;
    text-transform: uppercase;
}

.coupon__separator {
    height: 1px;
    margin: 10px 0;
    position: relative;
    width: 100%;
    z-index: 1;

    &::before {
        background-color: #f5f5f5;
        border-bottom-right-radius: 100%;
        border-top-right-radius: 100%;
        border: 1px solid #e0e0e0;
        border-left: none;
        content: "";
        height: 26px;
        left: -13px;
        position: absolute;
        top: -12px;
        width: 22px;
    }

    &::after {
        background-color: #f5f5f5;
        border-bottom-left-radius: 100%;
        border-top-left-radius: 100%;
        border: 1px solid #e0e0e0;
        border-right: none;
        content: "";
        height: 26px;
        position: absolute;
        right: -13px;
        top: -12px;
        width: 22px;
    }
}

.coupon__separator__line {
    border-top: 1px dashed #e5e5e5;
    height: 1px;
    margin: 0 auto;
    width: 90%;
}

.coupon__item__cta {
    align-items: center;
    background-color: #e40822;
    border-radius: 4px;
    display: flex;
    height: 44px;
    justify-content: center;
    position: relative;
    width: 100%;

    &--inverse {
        background-color: #fff;
        border: 1px solid #e40822;
    }

    &--clip::before {
        background-color: #fff;
        border-top-right-radius: 4px;
        border: 1px dashed #e40822;
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        content: "";
        height: 20px;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
    }

    &--clip::after {
        background-color: #fdc7cd;
        border-bottom-left-radius: 4px;
        border: 1px dashed #e40822;
        clip-path: polygon(0 0, 0 100%, 100% 100%);
        content: "";
        height: 20px;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
    }
}

.coupon__item__cta p {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    padding: 0 12px;
    text-transform: uppercase;

    & strong {
        font-size: 18px;
    }
}

.coupon__item__cta--inverse p {
    color: #e40822;
}

.coupon__item__description {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
}

.coupon__item__description__text {
    margin: 0;
}

.coupon__item__carrot {
    background-color: #666;
    bottom: 1px;
    display: inline-block;
    height: 6px;
    margin-left: 2px;
    position: relative;
    width: 8px;

    &--down {
        clip-path: polygon(0 0, 50% 100%, 100% 0);
    }

    &--up {
        clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
}

.coupon__item__details {
    display: none;

    &--open {
        display: flex;
        flex-flow: column;
    }
}

.coupon__item__details li {
    list-style-position: inside;
    list-style-type: disc;
    margin: 0;
    padding: 0;
    max-width: 80ch;

    &:not(:last-child) {
        margin-bottom: 4px;
    }

    & strong {
        font-weight: 700;
    }

    &:has(strong) {
        list-style: none;
    }
}