/*-----------------------------------------------------------------------------------*/
/*	Fonts
/*-----------------------------------------------------------------------------------*/
@font-face {
  font-family: "Quicksand";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand-Light.woff2") format("woff2");
}
@font-face {
  font-family: "Quicksand";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Quicksand";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Quicksand-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Lobster";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Lobster-Regular.woff2") format("woff2");
}
/*-----------------------------------------------------------------------------------*/
/*	GENERAL STYLING
/*-----------------------------------------------------------------------------------*/
body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Quicksand", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: hsl(0, 0%, 34.9%);
  background-color: #fff;
  width: 100%;
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
}
.overflow-hidden {
  overflow: hidden;
}

#page {
  background-color: hsl(0, 0%, 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mobile_select {
  display: none;
}

.menu_toggler {
  display: none;
}

.parallax {
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  padding-left: 40px;
  padding-right: 40px;
  max-width: initial;

  @media screen and (max-width: 1199px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width: 1680px) {
    padding-left: calc((100vw - 1600px) / 2);
    padding-right: calc((100vw - 1600px) / 2);
  }
}


.section-teaser {
	height: 360px;
	position: relative;
  margin: -20px 0px 20px;
  max-height: 25vh;
  font-family: "Lobster", Helvetica, sans-serif;

  @media screen and (min-width: 1199px) {
    margin-top: -40px;
    max-height: 35vh;
  }

  &:before {
    align-items: center;
    color: white;
    content: attr(teaser);
    display: flex;
    font-size: min(14vh, 14vw);
    font-weight: 700;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &--brands:before {
    @media screen and (max-width: 1199px) {
      font-size: min(10vh, 10vw);
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(249,86,78);
    background: linear-gradient(260deg, #f9564e 0%, #060606 100%);
    opacity: 0.5;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    object-position: center top;
    width: 100%;
  }
}

.shop {
  flex: 1 1 auto;
  overflow-x: hidden;
  width: 100%;

  @media screen and (min-width: 1200px) {
    z-index: 0;
  }
}

#shop_row {
  display: flex;
}

#sidebar {
  align-self: flex-start;
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 250px;
  margin-top: -10px;
  overflow: hidden;
  position: static;
  top: 0;
  width: 250px;
}

#main_content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

a,
input,
select,
textarea {
  vertical-align: top;
  outline: none !important;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

button,
input[type="search"] {
  -webkit-appearance: none;
}

select:-moz-focusring {
  color: transparent;
  outline: none !important;
  text-shadow: 0 0 0 #000 !important;
  border: 0 !important;
}

textarea {
  resize: none;
}

/*img {
	outline:0;
	vertical-align:top;
	border:0;
	max-width:90%;
	max-height: 100%;
	height:auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}*/
.banner {
  display: block;
  position: relative;
}
.top_sale_banners img {
  border: 0 none;
  height: auto;
  max-width: 100.1%;
  outline: 0 none;
  vertical-align: top;
}
.content_section img {
  border: 0 none;
  height: auto;
  max-width: 100.1%;
  outline: 0 none;
  vertical-align: top;
}
.content_section .img_left {
  float: left;
  margin-right: 48px;
  margin-bottom: 40px;
  max-width: 100%;
}
.content_section .img_right {
  float: right;
  margin-left: 48px;
  margin-bottom: 40px;
  max-width: 100%;
}
.content_section figcaption {
  text-align: center;
  font-weight: 900;
}

#impcon {
  position: initial;
}

a {
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

.clear {
  clear: both;
  height: 0;
  font-size: 0;
}

.visibility_hidden {
  visibility: hidden !important;
}

.margbot0 {
  margin-bottom: 0 !important;
}
.margbot5 {
  margin-bottom: 5px !important;
}
.margbot10 {
  margin-bottom: 10px !important;
}
.margbot15 {
  margin-bottom: 15px;
}
.margbot20 {
  margin-bottom: 20px;
}
.margbot25 {
  margin-bottom: 25px;
}
.margbot30 {
  margin-bottom: 30px !important;
}
.margbot35 {
  margin-bottom: 35px !important;
}
.margbot40 {
  margin-bottom: 40px;
}
.margbot50 {
  margin-bottom: 50px;
}
.margbot60 {
  margin-bottom: 60px;
}
.margbot70 {
  margin-bottom: 70px;
}
.margbot80 {
  margin-bottom: 80px;
}
.margbot90 {
  margin-bottom: 90px;
}
.margbot100 {
  margin-bottom: 100px;
}

.margtop10 {
  margin-top: 10px !important;
}
.margtop15 {
  margin-top: 15px !important;
}
.margtop50 {
  margin-top: 50px !important;
}

.pad0 {
  padding: 0 !important;
}
.padbot0 {
  padding-bottom: 0 !important;
}
.padbot10 {
  padding-bottom: 10px !important;
}
.padbot20 {
  padding-bottom: 20px !important;
}
.padbot30 {
  padding-bottom: 30px !important;
}
.padbot40 {
  padding-bottom: 40px !important;
}
.padbot50 {
  padding-bottom: 50px !important;
}
.padbot60 {
  padding-bottom: 60px !important;
}
.padbot70 {
  padding-bottom: 70px !important;
}
.padbot80 {
  padding-bottom: 80px !important;
}
.padbot90 {
  padding-bottom: 90px !important;
}
.padbot100 {
  padding-bottom: 100px !important;
}
.padtop0 {
  padding-top: 0 !important;
}

.border0 {
  border: 0 !important;
}

.selection {
  display: flex;
  margin-top: -10px;
  padding: 16px 0;
}
.selection__option {
  color: #999;
  flex: 0 1 auto;
  font-size: 26px;
  font-weight: 700;
  margin-right: 1em;
  position: relative;
  text-transform: uppercase;
}
.selection__option--inactive {
  color: #ddd;
  cursor: not-allowed;
}
.selection__option--inactive:hover {
  color: #ddd;
}

/*-----------------------------------------------------------------------------------*/
/*	Cookie Consent
/*-----------------------------------------------------------------------------------*/
div.cc-window {
  font-size: 11px;
}
div.cc-window a.cc-btn {
  font-size: 13px;
}

/*-----------------------------------------------------------------------------------*/
/*	TYPOGRAPHY
/*-----------------------------------------------------------------------------------*/
hr {
  margin: 33px 0;
  border: 0;
  border-top: 1px solid #e9e9e9;

  @media screen and (max-width: 767px) {
    margin: 24px 0;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
}
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.brand_section ul {
  -webkit-column-width: 200px;
  -moz-column-width: 200px;
  column-width: 200px;
  margin-left: 10px;
}

p {
  margin: 0 0 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 400;
  color: #666;
}
h1 b,
h2 b,
h3 b,
h4 b,
h5 b,
h6 b {
  font-weight: 700;
}
h1 {
  margin: 0 0 28px;
  font-size: 26px;
}
h2 {
  margin: 0 0 25px;
  line-height: 24px;
  font-size: 20px;
} /*used*/
h3 {
  margin: 0 0 21px;
  line-height: 20px;
  font-size: 16px;
} /*used*/
h4 {
  margin: 0 0 18px;
  line-height: 20px;
  font-size: 13px;
} /*used*/
h5 {
  margin: 0 0 15px;
  line-height: 17px;
  font-size: 12px;
} /*used*/
h6 {
  margin: 0 0 15px;
  line-height: 15px;
  font-size: 10px;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

.color_text {
  color: #30a67c;
}
.color_red {
  color: #cc3333;
}

.title {
  margin-bottom: 27px;
  padding-bottom: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.title h3 {
  margin: 0 0 9px;
  font-weight: 900;
}
.title p {
  font-size: 14px;
}

.title2 {
  margin-bottom: 9px;
}
.title2 h2 {
  margin: 0 0 25px;
  text-transform: none;
  font-weight: 400;
  line-height: 28px;
  font-size: 24px;
  color: #242424;
}
.title2 p {
  line-height: 24px;
  font-size: 14px;
}
#homepage h3 {
  margin: 0 0 25px;
  font-size: 20px;
  line-height: 28px;
  background-color: #f5f5f5;
  font-weight: 900;
}
.content_section h4 {
  font-size: 16px;
  font-weight: 900;
}
.featured_section h3#sale a {
  color: #cc3333;
}

.catalog_headline__container {
  align-items: baseline;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-flow: row;
  gap: 80px;
  justify-content: space-between;
  margin-bottom: 13px;
  padding-bottom: 13px;
}
.catalog_headline__container--brand {
  left: 15px;
  position: absolute;
  top: -170px;
  align-items: flex-end;
}
@media screen and (max-width: 600px) {
  .catalog_headline__container--brand {
    top: -125px;
  }
}
.catalog_headline__logo {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 8px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
  flex: 0 0 200px;
  height: 150px;
}
@media screen and (max-width: 600px) {
  .catalog_headline__logo {
    border-radius: 6px solid #fff;
    flex: 0 0 133px;
    height: 100px;
  }
}
.catalog_headline__text {
  color: #595959;
  flex: 1 1 auto;
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.25px;
  line-height: 1;
  margin: auto 0;
}
.catalog_headline__text--brand {
  color: #fff;
  font-size: 22px;
  padding: 0 0 40px 15px;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  .catalog_headline__text {
    font-size: 20px;
  }
  .catalog_headline__text--brand {
    padding: 0 0 20px 10px;
  }
}
@media screen and (max-width: 1199px) {
  .catalog_headline__text:not(.catalog_headline__text--brand) {
    margin-bottom: 12px;
    margin-top: 10px;
  }
}
.catalog_description {
  margin-right: 34px;
  margin-left: 18px;
  font-size: 13px;
}
.catalog_headline span {
  color: #666;
  font-weight: 400;
}
span#amazon_tag {
  color: #e47911;
  font-weight: 900;
}
.selection_headline {
  font-weight: 900;
  margin: 28px 0;
}

/*-----------------------------------------------------------------------------------*/
/*	SHORTCODES
/*-----------------------------------------------------------------------------------*/
/* __________ Buttons __________ */
.btn {
  display: inline-block;
  margin: 0;
  padding: 13px 30px;
  border: 1px solid #666;
  border-radius: 0;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 18px;
  font-size: 14px;
  color: #666;
  text-shadow: none;
  background: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.no-touch .btn:focus,
.no-touch .btn:hover {
  color: #fff;
  background-color: #242424;
}

.btn.inactive {
  color: #666;
  background: none;
  border-color: #ccc;
}
.no-touch .btn.inactive:hover {
  color: #666;
  background: none;
  border-color: #666;
}

.btn.active {
  color: #fff !important;
  background: #242424 !important;
  border-color: #242424;
}
.no-touch .btn.active:hover {
  color: #fff;
  background: #242424;
  border-color: #242424;
}

.btn.btn-white {
  color: #fff;
  background: none;
  border-color: #fff;
}
.no-touch .btn.btn-white:hover {
  color: #333;
  background: #fff;
  border-color: #fff;
}

.btn-sm {
  border-radius: 2px;
  font-size: 13px;
  padding: 13px 20px;
}

#load_more_button {
  position: relative;
  font-size: 16px;
  line-height: 48px;
  padding: 0;
  z-index: 2;
}

.btn_icon {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 16px;
  line-height: 46px;
  text-align: center;
}

#scroll_top_mobile {
  display: none;
}

.shortcodes_buttons .btn {
  margin-left: 10px;
  margin-right: 7px;
}

.selection .btn {
  border-radius: 2px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  line-height: 26px;
  width: 100%;
}
.selection .btn.inactive {
  box-shadow: none;
}
.no-touch .selection .btn.inactive:hover {
  border-color: #ccc;
  color: #666;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  cursor: not-allowed;
}

/* __________ Aktive Filter-Buttons __________ */
.sorting_bottom > span {
  font-size: 14px;
  margin: auto 1em auto 0;
}
.filter_button {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #c8c6c6;
  color: #666;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin: 0.5em 1em 0.5em 0;
  padding: 0.5em 1.8em 0.5em 0.5em;
  position: relative;
  white-space: nowrap;
}
.filter_button.active {
  color: #fff !important;
  background-color: #242424 !important;
}
.filter_button.active .close_btn {
  color: #fff;
}
.no-touch .filter_button:hover {
  background-color: #242424;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}
.filter_button .close_btn {
  display: block;
  float: none;
  font-size: 18px;
  line-height: 9px;
  color: #666;
  margin-top: -5px;
  position: absolute;
  right: 9px;
  top: 50%;
  width: 9px;
  opacity: 1;
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  padding: 0;
}
.no-touch .filter_button:hover .close_btn {
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
}
.filter_button span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  display: block;
  font-size: 14px;
  font-weight: 400;
  user-select: none;
}
.no-touch .filter_button:hover span {
  color: #fff;
  transition: all 0.3s ease-in-out 0s;
}

/* __________ Recommendation Button __________ */
.recommended_link {
  margin: 0 auto;
  max-width: 484px;
}
.recommended_link a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* __________ Blockquote __________ */
blockquote {
  position: relative;
  margin: 0 0 24px 70px;
  padding: 4px 0 7px 25px;
  border-left: 1px solid #ccc;
}
blockquote:before {
  content: "\f10d";
  position: absolute;
  left: -70px;
  top: 10px;
  width: 40px;
  height: 40px;
  font-family: fontello;
  line-height: 40px;
  font-size: 40px;
  color: #333;
}

/* __________ Alerts __________ */
.alert {
  position: relative;
  margin-bottom: 0px;
  padding: 6px 43px 4px 17px;
  font-size: 10px;
  color: #fff;
  border-radius: 0;
  border-width: 2px;
  border-style: solid;
  background-color: #666;
  border-color: #666;
}
.alert strong {
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 18px;
  font-size: 14px;
}
.alert .close {
  position: absolute;
  top: 2px;
  right: 17px;
  float: none;
  display: block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 26px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.no-touch .alert .close:hover {
  opacity: 0.5;
}

.alert-danger {
  background-color: #ffeded;
  border-color: #cc3333;
}
.alert-danger strong,
.alert-danger .close {
  color: #cc3333;
}

.alert-success {
  background-color: #e3ffde;
  border-color: #339933;
}
.alert-success strong,
.alert-success .close {
  color: #339933;
}

.alert-info {
  background-color: #666;
  border-color: #666;
}
.alert-info strong,
.alert-info .close {
  color: #fff;
}

.alert-warning {
  background-color: #fff6db;
  border-color: #cc9933;
}
.alert-warning strong,
.alert-warning .close {
  color: #cc9933;
}

.alert-info a {
  color: #fff;
  text-decoration: underline;
}

/* __________ List Styles __________ */
.list1 li {
  position: relative;
  padding: 0 0 10px 15px;
}
.list1 li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 6px;
  height: 6px;
  border: 2px solid #333;
  border-radius: 50%;
}

/*-----------------------------------------------------------------------------------*/
/*	Header-Top
/*-----------------------------------------------------------------------------------*/
.header_top {
    background-color: #f9f9f9;
    border-top: 3px solid #f9564e;
    height: 35px;
    width: 100%;
    z-index: 1;

    &__list {
      text-align: center;
      height: 35px;
      overflow: hidden;

      &_item {
        display: inline-block;
        padding: 5px 10px;
        white-space: nowrap;

        &_content {
          display: flex;
        }

        &_icon {
          width: 14px;
          stroke-width: 3px;
          margin: 0 4px;
        }
      }
    }
}


/*-----------------------------------------------------------------------------------*/
/*	PAGE OVERLAY
/*-----------------------------------------------------------------------------------*/
.page-overlay {
  background-color: rgba(0, 0, 0, 0.85);
  contain: strict;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  width: 100vw;
  will-change: transform;
  z-index: 4;
  transition: opacity 0.3s ease-in-out;

  &--content {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.page-overlay--visible {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.page-overlay--filter-visible {
  transform: translate3d(0, 0, 0);
  z-index: 6;
  opacity: 1;
}



/*-----------------------------------------------------------------------------------*/
/*	Sidepanel
/*-----------------------------------------------------------------------------------*/
.sidepanel {
  contain: content;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  margin: 0 18px 10px 0px;
  max-height: 340px;
  min-height: 60px;
  overflow: hidden;
  padding: 35px 0px 7px;
}
.sidepanel:last-of-type {
  border: none;
}

@media screen and (max-device-height: 1000px) {
  .sidepanel--min-height {
    display: none;
  }
}

.sidepanel--no-shrink {
  flex: 1 0 auto;
  max-height: none;
}

.sidepanel--expanded {
  min-height: 128px;
}

.sidepanel__headline {
  border-bottom: 1px solid #e9e9e9;
  color: hsl(0, 0%, 34.9%);
  display: block;
  flex: 0 0 auto;
  font-weight: 700;
  margin: 0 0 15px;
  padding-bottom: 4px;
  position: relative;
  text-transform: uppercase;
  user-select: none;
}

.sidepanel__content {
  display: none;
  flex: 1 1 auto;
  font-size: 14px;
  line-height: 1.8;
  overflow-y: auto;
  padding-left: 18px;
}

.sidepanel--expanded .sidepanel__content {
  display: block;
}

.sidepanel.content a {
  text-decoration: underline;
  color: #666;
}

.blog .sidepanel {
  margin-bottom: 20px;
}

.widget_search {
  margin-bottom: 27px;
  padding: 20px;
  border: 0;
  background-color: #f5f5f5;
}

.widget_search .search_form {
  position: relative;
}
.widget_search .search_form:before {
  content: "\f002";
  position: absolute;
  top: 8px;
  right: 0;
  width: 28px;
  height: 11px;
  font-family: fontello;
  text-align: center;
  line-height: 11px;
  font-size: 10px;
  color: #666;
  border-left: 1px dotted #999;
}
.search_form input[type="text"] {
  display: block;
  padding: 0 35px 0 8px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #666;
  border: 2px solid #e9e9e9;
  background-color: #fff;
}
.search_form input[type="text"]:focus {
  border-color: #ccc;
}

/* __________ Categories __________ */
.widget_categories .sidepanel__content ul:nth-child(n + 1) {
  padding-left: 20px;
}
.widget_categories__active_icon {
  height: 100%;
  left: -20px;
  position: absolute;
  width: 24px;

  &--medium {
    left: -18px;
    width: 20px;
  }

  &--small {
    left: -14px;
    width: 16px;
  }
}
.widget_categories__list_item {
  border: none;
  padding: 0;
  position: relative;
}
.widget_categories__item_link {
  color: hsl(0, 0%, 34.9%);
  display: block;
  font-size: 16px;
  line-height: 1.8;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  &--large {
    font-size: 20px;
  }

  &--small {
    font-size: 14px;
  }
}
.widget_categories__list_item.active:before {
  color: hsl(0, 0%, 34.9%);
}
.widget_categories__list_item.active .widget_categories__item_link {
  color: hsl(0, 0%, 34.9%);
  font-weight: 700;
}
.widget_categories__list_item.inactive .widget_categories__item_link {
  color: #d4d4d4;
}
.no-touch .widget_categories__list_item.inactive .widget_categories__item_link:hover {
  color: #d4d4d4;
  cursor: auto;
}


/*-----------------------------------------------------------------------------------*/
/*	Cookie Consent
/*-----------------------------------------------------------------------------------*/
.cookie-consent {
  background-color: #b9b9b9;
  color: #fff;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.cookie-consent__inner-container {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1500px;
}
.cookie-consent__link {
  color: #fff;
  text-decoration: underline;
}
.cookie-consent__link:hover {
  color: #fff;
  text-decoration: underline;
}
.cookie-consent__text {
  flex: 1 1 auto;
  padding: 10px;
}
.cookie-consent__action {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
}
@media screen and (max-width: 500px) {
  .cookie-consent__action {
    align-items: center;
    flex-direction: column;
  }
}
.cookie-consent__button {
  cursor: pointer;
  padding: 10px 15px;
}
.cookie-consent__close {
  cursor: pointer;
  height: 40px;
  padding: 10px 15px;
  width: 50px;
}

/*-----------------------------------------------------------------------------------*/
/*	HEADER
/*-----------------------------------------------------------------------------------*/
.header__container {
  background-color: #fff;
  border-bottom: 1.5px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 10px 40px 0;
  position: relative;
  width: 100%;
  z-index: 2;

  @media screen and (max-width:1199px) {
    background-color: rgba(255, 255, 255, 0.96);
    margin-bottom: 20px;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    position: sticky;
    top: 0;
    transition: all 0.3 ease-in-out;
    z-index: 5;
  }

  @media screen and (min-width:1680px) {
    padding-left: calc((100vw - 1600px) / 2);
    padding-right: calc((100vw - 1600px) / 2);
  }
}
@media (max-width: 1199px) {
  .header__container--non-transparent {
    background-color: rgba(255, 255, 255, 1);
  }
}
.header__container--clean:after {
  background: none;
}
.header__sub-container {
  display: flex;
  flex: 1 1 auto;
}
.header__sub-container--right {
  flex: 0 1 auto;
  justify-content: flex-end;
  margin-left: auto;
  z-index: 1;
}
.header__nav-container {
  display: flex;
  flex: 0 0 60px;
  height: 60px;
  margin: 0 auto;
  position: relative;
  width: 100%;
}
.header__nav-container--sub {
  flex: 0 0 46px;
  height: 46px;
}
@media (max-width: 1199px) {
  .header__nav-container--sub {
    display: none;
  }
}
.header__nav-button-container {
  display: flex;
  height: 100%;
  z-index: 1;
}
@media (max-width: 1199px) {
  .header__nav-button-container {
    display: none;
  }
}
.header__nav-button {
  margin-right: 3px;
}
.header__nav-button-link {
  border-radius: 2px;
  color: #404040cf;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.025em;
  margin: 0 16px;
  position: relative;
  text-transform: uppercase;
}
@media screen and (min-width: 1199px) {
  .header__nav-button-container
    .header__nav-button:first-of-type
    .header__nav-button-link {
    margin-left: 4px;
  }
}
.header__nav-button-link:hover,
.header__nav-button-link--active {
  color: #666;
}
.header__nav-button-link:focus {
  color: #666;
}
.header__nav-button-link:hover:after,
.header__nav-button-link--active:after {
  background-color: #666;
  bottom: 14px;
  content: "";
  height: 2px;
  position: absolute;
  width: 100%;
}
.header__logo-container {
  display: flex;
  flex: 1 1 auto;
  height: 60px;
  justify-content: flex-start;
  left: 0;
  padding-left: 54px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
@media screen and (min-width: 550px) {
  .header__logo-container {
    justify-content: center;
    padding: 0;
  }
}
.header__logo-link {
  display: inline-block;
  height: inherit;
  padding: 12px 0;
  z-index: 1;
}
.header__logo {
  height: 100%;
  max-width: 50vw;
}
.header__nav-item-container {
  display: flex;
}
.header__nav-item {
  color: hsl(0, 0%, 34.9%);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0.025em;
  margin: 0 16px;
  position: relative;
}
@media screen and (min-width: 1199px) {
  .header__nav-item-container:first-of-type .header__nav-item {
    margin-left: 4px;
  }
}
.header__nav-item--less {
  font-size: 14px;
  font-weight: 400;
}
.header__nav-item:hover,
.header__nav-item:active,
.header__nav-item:focus {
  color: #404040cf;
}
.header__nav-item:after {
  background-color: #f9564e;
  bottom: 7px;
  content: "";
  height: 2px;
  left: 50%;
  position: absolute;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 0%;
}
.header__nav-item:hover:after {
  left: 0;
  width: 100%;
}
.header__nav-item-child-container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  contain: layout;
  left: -9999;
  max-width: calc(100vw - 40px);
  padding: 25px 24px;
  position: absolute;
  top: 46px;
  visibility: hidden;
  width: calc(150px * 8 + 8px * 8 - 8px + 48px);
  z-index: 999;

  @media screen and  (max-width: 1350px) {
    width: calc(150px * 7 + 8px * 7 - 8px + 48px);
  }
}
.header__nav-item-child-container--active {
  left: 0;
  visibility: visible;
}
.header__nav-item-child-container-arrow {
  display: none;
}
.header__nav-item-child-container--active .header__nav-item-child-container-arrow {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  display: block;
  height: 10px;
  left: 50px;
  position: absolute;
  top: -10px;
  transform: translateX(-50%) translateY(50%) rotate(45deg);
  width: 10px;
}
.header__nav-item-child-container--active:after {
  background-color: #fff;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.header__nav-item-child-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 8px;
  max-height: 388px;
  overflow: hidden;
}
.header__nav-item-child-category {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #fb9573;
  display: flex;
  flex-flow: column;
  height: 190px;
  justify-content: flex-end;
  letter-spacing: 0.025em;
  line-height: 1;
  position: relative;
  width: 150px;
}
.header__nav-item-child-category-image {
  filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.5));
  height: 150px;
  left: 0;
  object-fit: contain;
  padding: 20px;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease-in-out,filter 0.3s ease-in-out;
  width: 100%;

  .header__nav-item-child-category:hover & {
    transform: scale(1.1);
    filter: drop-shadow(8px 8px 9px rgba(0, 0, 0, 0.5));
  }
}
.header__nav-item-child-category-text {
  align-items: center;
  background-color: #fb9573;
  color: #fff;
  display: flex;
  flex: 0 0 36px;
  font-size: 12px;
  font-weight: 700;
  hyphens: auto;
  justify-content: center;
  overflow-wrap: break-word;
  padding: 6px 8px;
  text-align: center;
  width: 100%;
}
.header__nav-item-child-container-hr {
    margin: 20px 0 14px;
}
.header__nav-item-child-container-text {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
}
.header__mobile-container {
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 1;
}
@media (min-width: 1200px) {
  .header__mobile-container {
    display: none;
  }
}
.header__mobile-btn-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  height: 100%;
  justify-content: center;
}
.header__mobile-btn-container--spacer {
  flex: 1 1 auto;
}
.header__mobile-btn {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-flow: column;
  height: 46px;
  justify-content: center;
  stroke: #666;
  width: 46px;
}
.header__mobile-btn-text {
  -moz-user-select: none;
  -webkit-user-select: none;
  color: #333;
  font-size: 8px;
  line-height: 1;
  user-select: none;
}
.header__mobile-btn-svg {
  -webkit-user-select: none;
  stroke: #666;
  user-select: none;
}
.header__mobile-btn-svg * {
  -webkit-user-select: none;
  user-select: none;
}
.header__mobile-btn-svg--search {
  position: relative;
  right: -1px;
}
.header__mobile-btn-svg--menu {
  position: relative;
  left: -1px;
}
@media screen and (min-width: 375px) {
  .header__mobile-btn-link {
    margin-right: 4px;
  }
}
@media screen and (min-width: 400px) {
  .header__mobile-btn-link {
    margin-right: 10px;
  }
}

/* __________ Mobile Menu __________ */
nav#mobile-menu {
  display: none;
}
.mm-ocd {
  contain: strict;
  font-size: 18px;
}

/* __________ Menu __________ */
.menu_block {
  background-color: #fff;
}
.is-sticky .menu_block {
  z-index: 9000;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 0 #e9e9e9;
}
.menu_block div.container:nth-child(1) {
  position: relative;
}
.menu_block div.container:nth-child(n + 2) {
  margin-top: 14px;
}
.menu_block div.container:nth-child(3) {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 0;
  max-width: 1500px;
}
.navmenu {
  float: left;
}
.navmenu li {
  position: relative;
  display: inline-block;
  margin: 0 0 0 0;
}
.navmenu.secondnav > li:nth-child(4) {
  border-right: 1px solid #efefef;
}
.navmenu.secondnav > li:nth-child(5) a,
.navmenu.secondnav > li:nth-child(6) a {
  font-weight: 400;
}
.no-touch .navmenu li.sub-menu:hover {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #f0f0f0 0%, #ffffff 90%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f0f0f0),
    color-stop(90%, #ffffff)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #f0f0f0 0%,
    #ffffff 90%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #f0f0f0 0%,
    #ffffff 90%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f0f0f0 0%, #ffffff 90%); /* IE10+ */
  background: linear-gradient(to bottom, #f0f0f0 0%, #ffffff 90%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0f0f0', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.no-touch .navmenu li.sub-menu:hover:before {
  content: "";
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: -2px;
  height: 3px;
  background-color: #fff;
}

.navmenu li a {
  position: relative;
  display: block;
  margin: 0;
  padding: 20px 16px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 20px;
  font-size: 14px;
  color: #999;
  transition: none;
  -webkit-transition: none;
}
.navmenu.secondnav li a {
  font-size: 13px;
  padding: 12px 16px;
}
.navmenu.secondnav li {
  border-bottom: 2px solid #fff;
}
.navmenu.secondnav > li.active > a {
  border-bottom: 2px solid #999;
  margin-bottom: -2px;
}
.no-touch .navmenu.secondnav > li:hover > a {
  border-bottom: 2px solid #999;
  margin-bottom: -2px;
}
.navmenu li.sale_menu a {
  color: #cc3333;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.navmenu li.sale_menu a:before,
.navmenu li.sale_menu a:after {
  display: none;
}

.no-touch .navmenu li:hover a {
  background-color: #666;
  color: #fff;
}
.navmenu li.active a {
  background-color: #999;
  color: #fff;
}
.navmenu.secondnav li:hover a,
.navmenu.secondnav li.active a {
  background-color: #fff;
  color: #999;
}

.navmenu li.sale_menu:hover,
.navmenu li.sale_menu.active {
  border-bottom: 2px solid #cc3333;
}
.navmenu li.sale_menu:hover a,
.navmenu li.sale_menu.active a {
  margin: 0;
  border: 0;
  color: #fff;
  background-color: #cc3333;
}

/* __________ Submenu __________ */

.navmenu li.sub-menu ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 9990;
  width: 100%;
  left: -1px;
  top: 100%;
  padding: 19px 0 3px;
  margin: 2px 0 0;
  background: #fff;
  border-radius: 0;
  border: 1px solid #e9e9e9;
}
.no-touch .navmenu li.sub-menu:hover ul {
  visibility: visible;
  opacity: 1;
}
.navmenu li.sub-menu.last ul {
  right: 0;
  left: auto;
}
.navmenu li.sub-menu ul li {
  margin: 0;
  padding: 0;
  text-align: left;
  border-top: 0;
}
.navmenu li.sub-menu ul li.active {
  background-color: #f5f5f5;
}
.navmenu li.sub-menu ul li.col_head {
  background-color: #fff;
}
.navmenu li.sub-menu ul li.col_head a {
  font-weight: 900;
  color: #999;
}
.navmenu li.sub-menu ul li a {
  position: relative;
  margin: 0;
  padding: 0 0 0px 10px;
  line-height: 25px;
  font-size: 11px;
  font-weight: 400;
  color: #666;
  border: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.no-touch .navmenu li.sub-menu ul li a:hover {
  text-decoration: underline;
}
.navmenu li.sub-menu ul li a:before {
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 14px;
  color: #ccc;
  background: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.navmenu li.sub-menu ul li a:after {
  background: none;
}

.navmenu li.sub-menu ul li.active a,
.no-touch .navmenu li.sub-menu ul li a:hover {
  color: #333;
}
.navmenu li.sub-menu ul li.active a:before,
.no-touch .navmenu li.sub-menu ul li a:hover:before {
  color: #333;
}

/* __________ Mega Menu ________ */
.navmenu li.sub-menu ul.megamenu_col1 {
  width: 190px;
}
.navmenu li.sub-menu ul.megamenu_col2 {
  width: 370px;
}
.navmenu li.sub-menu ul.megamenu_col3 {
  width: 550px;
}
.navmenu li.sub-menu ul.mega_menu {
  padding-bottom: 15px;
}
.navmenu li ul.mega_menu li.col {
  float: left;
  width: 180px;
  margin: 0;
  padding-left: 15px;
}
.navmenu li ul.mega_menu li ol {
  padding-left: 0;
}
.navmenu li ul.mega_menu li ol li {
  float: none;
  display: block;
}
.navmenu li ul.mega_menu li ol li a {
  border: 0;
}

/* _________ //Mega Menu _______ */

/* Secondary */
.secondary_menu {
  float: left;
}
.secondary_menu li {
  position: relative;
  display: inline-block;
  margin-right: 11px;
  padding-right: 12px;
}
.secondary_menu li:before {
  content: "";
  position: absolute;
  right: 0;
  top: 6px;
  height: 9px;
  border-right: 1px dotted #999;
}
.secondary_menu li:last-child:before {
  display: none;
}
.secondary_menu li a {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  color: #333;
}
.no-touch .secondary_menu li a:hover {
  color: #242424;
}

/* Wishlist */
.header__wishlist-container {
  border: 12px solid transparent;
  cursor: pointer;
  display: block;
  height: 60px;
}
@media screen and (max-width: 1199px) {
  .header__wishlist-container {
    display: none;
  }
}
.wishlist-icon {
  height: 100%;
  width: auto;
}
.wishlist-icon__counter {
  transform-box: fill-box;
  transform-origin: center;
  transform: scale(0);
}
.wishlist-icon--active .wishlist-icon__counter {
  animation: bump-in-counter 0.3s linear;
  animation-fill-mode: forwards;
}
.wishlist-icon__path {
  stroke: #333;
  stroke-width: 3;
}
.wishlist-icon__circle {
  fill: #f9564e;
}
@keyframes bump-in-counter {
  75% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

/*	Search Form */
.header__search-container {
  display: flex;
  flex: 0 1 auto;
  height: 100%;
  justify-content: flex-end;
  padding: 7px 0;
  position: relative;
}
@media screen and (max-width: 1199px) {
  .header__search-container {
    background-color: white;
    border-bottom: 1px solid #e9e9e9;
    display: block;
    height: 60px;
    left: 0;
    opacity: 1;
    padding: 0 10px;
    position: fixed;
    top: 0;
    transform: translate3d(100%, 0, 0);
    transition: all 0.3s ease-in-out;
    width: 100vw;
  }
  .header__search-container--visible {
    transform: translate3d(0, 0, 0);
  }
}
.header__search-form {
  display: flex;
  flex: 0 1 auto;
  height: 100%;
  position: relative;
  transition: width 0.2s ease-in-out;
  width: 100%;
}
@media screen and (min-width: 1199px) {
  .header__search-form {
    width: 230px;
    will-change: width;
  }
  .header__search-form:focus-within {
    width: 350px;
  }
}
.header__search-form-svg {
  border: 10px solid transparent;
  fill: none;
  height: 40px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 6;
  stroke: #666;
  width: 40px;
}
.header__search-form-svg--hidden {
  visibility: hidden;
}
.header__search-form-svg--back {
  margin: auto 5px auto -5px;
}
.header__search-form-svg--delete {
  margin: 10.5px -6px 10.5px 0;
  position: absolute;
  height: 38px;
  right: 0;
  width: 38px;
}
@media screen and (min-width: 1200px) {
  .header__search-form-svg--back {
    display: none;
  }
  .header__search-form-svg--delete {
    display: none;
  }
}
.header__search-input-wrapper {
  flex: 1 1 auto;
  position: relative;
  background-color: #fff;
}
@media screen and (max-width: 1199px) {
  .header__search-input-wrapper {
    padding-right: 5px;
  }
}
.header__search-input {
  border-bottom: 2px solid rgb(249, 86, 78) !important;
  border-radius: 2px;
  border: none;
  color: #666;
  flex: 1 1 auto;
  font-size: 16px;
  height: 100%;
  min-width: 180px;
  padding: 5px 10px 0 0;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .header__search-input {
    font-size: 18px;
    padding-right: 26px;
  }
}
.header__search-input:focus {
  border-bottom: 2px solid rgb(249, 86, 78) !important;
  border: none;
  color: #333;
}
.header__search-result-list {
  background-color: #fff;
  border-top: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  height: 0;
  left: 0;
  padding: 0 10px 10px;
  position: absolute;
  top: 46px;
  transition: height 0.3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 3;
}
@media screen and (max-width: 1199px) {
  .header__search-result-list {
    background-color: #fff;
    border: none;
    box-shadow: none;
    contain: content;
    left: 0;
    height: auto;
    max-height: calc(100vh - 60px);
    overflow-y: scroll;
    padding: 0 10px 0 50px;
    position: fixed;
    top: 60px;
    transform: translate3d(100%, 0, 0);
    transition: all 0.3s ease-in-out;
    visibility: visible !important;
    width: 100%;
    z-index: 3;
  }
}
.header__search-result-list--visible {
  height: auto;
  visibility: visible;
}
@media screen and (max-width: 1199px) {
  .header__search-result-list--visible {
    transform: translate3d(0, 0, 0);
  }
}
.header__search-result {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 900;
}
.header__search-result:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #f9564e;
}
@media screen and (max-width: 1199px) {
  .header__search-result {
    font-size: 14px;
  }
  .header__search-result:first-child {
    padding-top: 12px;
  }
  .header__search-result:last-child {
    padding-bottom: 14px;
  }
}
@media screen and (min-width: 500px) and (max-width: 1199px) {
  .header__search-result {
    flex-flow: row-reverse;
  }

  .header__search-result-text {
    position: relative;
    left: -40px;
  }

  .header__search-result-icon {
    left: -45px;
    transform: rotateZ(90deg);
  }
}
.header__search-result-marker {
  font-weight: 400;
}
.header__search-result-text {
  -moz-user-select: none;
  -webkit-user-select: none;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
.header__search-result-icon {
  border: 10px solid transparent;
  box-sizing: content-box;
  flex: 0 0 auto;
  height: 20px;
  pointer-events: auto;
  position: relative;
  right: -7px;
  width: 20px;
}
.header__search-result-icon * {
  pointer-events: none;
}
.header__search-submit {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='107.518' height='107.518'%3E%3Cg fill='none' stroke='%23333' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10'%3E%3Ccircle cx='61.808' cy='45.71' r='37.192'/%3E%3Cpath d='M35.398 71.895L8.255 99.266'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 6px solid transparent;
  flex: 0 1 48px;
  height: 100%;
  padding: 0;
  width: 48px;
}
@media screen and (max-width: 1199px) {
  .header__search-submit {
    display: none;
  }
}
#ui-id-1 {
  padding: 0;
}
.ui-menu {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.ui-menu .ui-menu-item a {
  font-size: 16px;
  line-height: 2.5;
}

/*-----------------------------------------------------------------------------------*/
/*	HOME
/*-----------------------------------------------------------------------------------*/
#home {
  position: relative;
  padding: 0;
}

/*Top-Slider*/
.top_slider {
  height: 70vh;
  margin-bottom: 80px;
  width: 100%;
}
.top_slider__track {
  height: inherit;
  width: inherit;
}
.top_slider__slides {
  height: inherit;
  width: inherit;
}
.top_slider__slide-container {
  position: relative;
}
.top_slider__slide-link {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.top_slider__slide-image {
  background-position: top;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.top_slider__slide-content-container {
  height: 100%;
  left: 5%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 90%;
  z-index: 1;
}
.top_slider__slide-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 55px;
  width: 30%;
}
.top_slider__slide-subheadline {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.25;
}
.top_slider__slide-rowwrapper {
  display: flex;
  flex-direction: row;
}
.top_slider__slide-headline {
  color: #fff;
  display: inline-block;
  font-size: 45px;
  margin-bottom: 20px;
  line-height: 1.42;
}
.top_slider__slide-cta-container {
  display: flex;
}
.top_slider__slide-cta {
  background-color: rgb(243, 243, 243);
  border: 1px solid #e6e6e7;
  color: rgb(6, 6, 6);
  font-size: 14px;
  line-height: 38px;
  min-height: 40px;
  overflow: hidden;
  padding: 0 40px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top_slider__arrow {
  background-color: hsla(0, 0%, 100%, 0.7);
  border-radius: 0;
  border: 0;
  color: rgb(24, 24, 24);
  height: 105px;
  outline: 0;
  padding: 0;
  transition: width 0.15s ease-in-out;
  width: 30px;
}
.top_slider__arrow:hover {
  width: 45px;
}
.top_slider__arrow--left {
  left: 0;
}
.top_slider__arrow--left::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9'%3E%3Cpath d='M4.779.19a.668.668 0 010 .945L1.543 4.357a.181.181 0 000 .26l3.262 3.248a.67.67 0 01-.007.951.65.65 0 01-.913-.006L.321 5.26a1.093 1.093 0 010-1.548L3.86.19a.65.65 0 01.918 0l-.156.171.157-.17z'/%3E%3C/svg%3E");
  content: "";
  display: block;
  height: 9px;
  margin: 0 auto;
  width: 5px;
}
.top_slider__arrow--right {
  right: 0;
}
.top_slider__arrow--right::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='9'%3E%3Cpath d='M.221 8.81a.668.668 0 010-.945l3.236-3.222a.181.181 0 000-.26L.195 1.135A.67.67 0 01.202.184a.65.65 0 01.913.006l3.564 3.55a1.093 1.093 0 010 1.548L1.14 8.81a.65.65 0 01-.918 0l.156-.171-.157.17z'/%3E%3C/svg%3E");
  content: "";
  display: block;
  height: 9px;
  margin: 0 auto;
  width: 5px;
}
.top_slider__bullet {
  height: 20px;
  width: 20px;
}

/*Teaser*/
.teaser {
  display: flex;
  flex-direction: column;
  margin-top: -40px;
}
@media screen and (max-width: 1199px) {
  .teaser {
    margin-top: -20px;
  }
}
.teaser__container {
  align-self: center;
  contain: content;
  display: flex;
  flex-direction: column;
  margin: 0 0 120px;
  width: 100%;
  padding: 0 10px;

  @media screen and (max-width: 1199px) {
    margin: 0 0 40px;
  }

  @media screen and (min-width: 1680px) {
    padding-left: calc((100vw - 1600px) / 2);
    padding-right: calc((100vw - 1600px) / 2);
  }

  &--open-right {
    padding-right: 0;
  }

  &--recently-visited {
    background-color: #faccbd;
    padding-top: 50px;

    @media screen and (max-width: 1199px) {
      padding-top: 28px;
    }
  }

  &--products {
    margin: 0;
  }
}
.teaser__container:last-of-type {
  margin-bottom: 50px;
}
@media screen and (max-width: 1500px) {
  .teaser__container {
    margin: 0 0 60px;

    &--products {
      margin: 0;
    }
  }
  .teaser__container:last-of-type {
    margin: 0 0 10px;
  }
}
.teaser__headline-container {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 40px;
  padding-bottom: 12px;

  @media screen and (max-width: 1199px) {
    margin-bottom: 10px;
  }

  .teaser__container--recently-visited & h2 {
    color: #fff;
  }

  &--top-brands {
    margin-bottom: 22px;

    @media screen and (max-width: 1199px) {
      margin-bottom: 10px;
    }
  }
}
.teaser__headline {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  text-transform: none;

  @media screen and (max-width: 1199px) {
    font-size: 22px;
  }
}
.teaser__subheadline {
  font-size: 16px;
  line-height: 1.5;
  margin: 5px 0 0;
}
.teaser__content-container--category {
  display: flex;
  height: 728px;
  position: relative;
  width: 100%;
  margin-top: 4px;
}
@media screen and (orientation: portrait) and (max-width: 500px) {
  .teaser__content-container--category {
    flex-direction: column-reverse;
    height: 600px;
  }
}
.teaser__container--recently-visited .teaser__content-container--products {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 -15px 70px;

  @media screen and (max-width: 1199px) {
    margin: 0 -15px 30px;
  }

  & .product {
    flex: 0 0 65vw;
    margin-bottom: 0;
  
    @media (min-width: 621px) {
        flex: 0 0 40vw;
    }
    @media (min-width: 828px) {
        flex: 0 0 28.57vw;
    }
    @media (min-width: 1366px) {
        flex: 0 0 22.22vw;
    }
    @media (min-width: 1680px) {
        flex: 0 0 15vw;
    }
  }

  & .product__image:after {
    background-color: #fff;
    z-index: -1;
  }
}
.teaser__content-container--products {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-rows: 0;
  margin: 0 -15px 40px;
  
  @media (min-width: 621px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 828px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1366px) {
    grid-template-columns: repeat(5, 1fr);
    margin: 0 -15px 70px;
  }
}


.teaser__content-container--top-categories {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

/*HeroSelect Teaser*/
.heroselect__container {
  flex-direction: row;
  max-height: 600px;
  min-height: 50vh;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .heroselect__container {
    flex-direction: column;
  }
}
.heroselect__select {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  flex: 1 0 auto;
  margin-right: 10px;
  position: relative;
}
@media screen and (max-width: 500px) {
  .heroselect__select {
    margin: 0 0 10px;
  }
}
.heroselect__select:last-of-type {
  margin: 0;
}
.heroselect__select:before {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}
.heroselect__select:hover:before {
  background-color: rgba(0, 0, 0, 0.4);
}
.heroselect__select:hover .heroselect__cta:after {
  left: 0;
  width: 100%;
}
.heroselect__link {
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.heroselect__cta {
  align-self: center;
  backdrop-filter: blur(2px);
  border-radius: 2px;
  color: #fff;
  font-size: calc(1em + 2vw);
  font-weight: 700;
  line-height: 1;
  padding: 5px 10xp;
  position: relative;
}
@media screen and (max-width: 500px) {
  .heroselect__cta {
    font-size: 24px;
  }
}
.heroselect__cta:after {
  background-color: #fff;
  bottom: -14px;
  content: "";
  height: 4px;
  left: 50%;
  position: absolute;
  transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
  width: 0;
}

/*Top-Categories*/
.top_categories__container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  margin: 0 -15px;
}
@media screen and (pointer: fine) {
  .top_categories__container {
    overflow-x: hidden;
  }
}
.top_categories__slide-link {
  aspect-ratio: 1 / 1.5;
  display: flex;
  flex-flow: column;
  flex: 0 0 65%;
  justify-content: center;
  margin: 0 12px;
  width: 100%;
  height: auto;

  &:first-of-type {
    margin-left: 0;
  }

  @media screen and (pointer: fine) {
      flex: 0 0 50%;
  }
  @media screen and (min-width: 621px) {
      flex: 0 0 40%;
  }
  @media screen and (pointer: fine) and (min-width: 621px) {
      flex: 0 0 33.333%;
  }
  @media screen and (min-width: 828px) {
      flex: 0 0 28.57%;
  }
  @media screen and (pointer: fine) and (min-width: 828px) {
      flex: 0 0 25%;
  }
  @media screen and (min-width: 1366px) {
      flex: 0 0 22.22%;
  }
  @media screen and (pointer: fine) and (min-width: 1366px) {
      flex: 0 0 20%;
  }
  @media screen and (pointer: fine) and (min-width: 1500px) {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.top_categories__image {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  flex: 1 1 auto;
  height: 100%;
  object-fit: contain;
  padding: 14px;
  width: 100%;
}
.top_categories__headline {
	align-items: center;
	background-color: #fcede6;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	color: #89181b;
	display: flex;
	flex: 0 0 64px;
	font-size: 22px;
	font-weight: 700;
	justify-content: center;
	text-align: center;
}

/*Single Category Teaser*/
.teaser__category-content {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 30px;
  position: relative;
  width: 50%;
}
@media screen and (max-width: 500px) {
  .teaser__category-content {
    flex: 1 0 0;
    width: 100%;
    padding: 30px 15px;
  }
}
.teaser__category-text {
  left: 18%;
  position: absolute;
  right: 10%;

  @media screen and (orientation: portrait) and (max-width: 500px) {
    position: static;
  }
}
.teaser__category-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 50%;
  border: 1.5vw solid transparent;
}
@media screen and (max-width: 500px) {
  .teaser__category-image {
    flex: 3 0 0;
    width: 100%;
    border: 15px solid transparent;
    border-bottom-width: 10px;
    height: 1px;
  }
}
.teaser__category-subheadline {
  font-size: 16px;
  line-height: 1.43;
}
@media screen and (max-width: 500px) {
  .teaser__category-subheadline {
    font-size: 13px;
  }
}
.teaser__category-headline {
  font-size: 40px;
  line-height: 1.43;
  margin-bottom: 32px;
  margin-top: 0;
}
@media screen and (max-width: 500px) {
  .teaser__category-headline {
    font-size: 30px;
    margin-bottom: 24px;
  }
}
.teaser__category-button {
  background: #fff;
  border: 1px solid #e6e6e7;
  box-sizing: border-box;
  color: #595959;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  min-height: 40px;
  outline: 0;
  padding: 18px 80px;
  text-align: center;
  white-space: nowrap;
  width: auto;
}
@media screen and (max-width: 500px) {
  .teaser__category-button {
    padding: 14px 50px;
  }
}
.teaser__content-container--top-brands {
  column-gap: 20px;
  column-width: 250px;
  font-size: 14px;
  line-height: 2;
  max-height: 280px;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    column-width: 160px;
  }

  & li {
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #f9564e;
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*	CATALOG
/*-----------------------------------------------------------------------------------*/
.catalog__header {
  display: block;
  height: 400px;
  margin-bottom: 40px;
  margin-top: -40px;
  max-height: 40vh;
  position: relative;
  width: 100%;
}
.catalog__header:after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.4)
  );
}
.catalog__header-image {
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

/*-----------------------------------------------------------------------------------*/
/*	Category Navigation
/*-----------------------------------------------------------------------------------*/
.category_navigation {
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 30px;
	margin-left: -10px;
	margin-right: -10px;
  overflow-x: auto;

  &__category_item {
      align-items: center;
      background-color: #fdede7;
      border-radius: 4px;
      color: #ae1818;
      display: flex;
      flex-flow: column;
      flex: 1 0 90px;
      margin: 10px;
  }

  &__category_item_icon {
    flex: 0 0 auto;
  }

  &__category_item_text {
    -webkit-hyphens: auto;
    -webkit-user-select: none;
    color: #ae1818;
    flex: 0 0 33px;
    font-size: 14px;
    font-weight: 700;
    hyphens: auto;
    line-height: 1;
    padding: 0px 5px 9px;
    text-align: center;
    user-select: none;
    word-break: break-word;
  }
}

/*-----------------------------------------------------------------------------------*/
/*	PRODUCT
/*-----------------------------------------------------------------------------------*/
.product__container {
  contain: content;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding-top: 15px;
}
.product__container-text-wrapper {
  margin: 0 15px;
}
.product {
  contain: content;
  flex: 0 0 50%;
  font-size: 14px;
  margin-bottom: 45px;
  min-width: 0;
  padding: 0 15px;
  position: relative;
}
@media screen and (max-width: 500px) {
  .product {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 621px) {
  .product {
    flex: 0 0 33.333%;
  }
}
@media screen and (min-width: 828px) {
  .product {
    flex: 0 0 25%;
  }
}
@media screen and (min-width: 1650px) {
  .product {
    flex: 0 0 20%;
  }
}
.product__link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.product__content {
  position: relative;
}
.product__sale {
  background-color: #f9564e;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  color: #fff;
  font-weight: 700;
  left: 0px;
  padding: 6px 8px;
  position: absolute;
  top: 12px;
  width: 50px;
  z-index: 2;

  @media screen and (max-width: 450px) {
    top: 8px;
  }
}
.product__image {
  border-radius: 4px;
  height: 0;
  overflow: hidden;
  padding-bottom: 150%;
  position: relative;
  width: 100%;
}
.product__image:after {
  background-color: rgba(0, 0, 0, 0.05);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.product__image_tag {
  -webkit-transform: translate(-50%, -50%);
  border-radius: 2px;
  left: 50%;
  height: 100%;
  width: 100%;
  padding: 5px;
  position: absolute;
  top: 50%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}
.product__text {
  color: #595959;
  display: flex;
  flex-flow: column;
  font-size: 13px;
  line-height: 2.3;
  margin-top: 6px;
  padding: 0 8px;

  @media screen and (min-width: 500px) {
    font-size: 14px;
  }
}
.product__description {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  line-height: 1.3;
  overflow: hidden;
}
.product__brand {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.product__name {
  height: calc(1em * 1.3);
  overflow: hidden;
}
.product__pricing {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}
.product__price {
  white-space: nowrap;
}
.product__price--old {
  padding-right: 8px;
  position: relative;

  &::after {
    background-color: #595959;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    width: calc(100% - 19px);
  }
}
.product__price--new {
  color: #f9564e;
  font-weight: 700;
}
.product__like {
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  fill: transparent;
  height: 25px;
  padding: 5px 4px 4px;
  position: absolute;
  right: 15px;
  stroke-width: 6;
  stroke: #595959;
  top: 0;
  transition: fill 0.3s linear, stroke 0.3s linear;
  width: 25px;
  z-index: 3;
}
@media screen and (max-width: 450px) {
  .product__like {
    border: 5px solid transparent;
  }
}
.product__like--active {
  fill: #f9564e;
  stroke: #f9564e;
}
.product__like--active svg {
  animation: 0.3s confirm linear;
}
.product__like:not(.product__like--active):hover svg {
  animation: 1s bump infinite linear alternate;
}
@keyframes bump {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.1);
  }
}
@keyframes confirm {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
.product__overlay-wrapper {
  align-items: center;
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.product__overlay-text {
  color: #fff;
  font-size: 16px;
  padding: 0 15px;
}

/*-----------------------------------------------------------------------------------*/
/*	SLIDER
/*-----------------------------------------------------------------------------------*/
#slider1,
#slider2 {
  float: left;
  padding-right: 98px;
}
#carousel1,
#carousel2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80px;
}
#carousel1 .slides li,
#carousel2 .slides li {
  overflow: hidden;
  float: none !important;
  margin-bottom: 10px;
  width: 80px;
  height: 100px;
}
#carousel1 .slides li img,
#carousel2 .slides li img {
  margin-bottom: 10px;
}
#carousel1 .flex-viewport,
#carousel2 .flex-viewport {
  height: 484px !important;
}
#carousel2 .flex-active-slide,
#carousel1 .flex-active-slide {
  position: relative;
}
#carousel1 .flex-active-slide:before,
#carousel2 .flex-active-slide:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #e9e9e9;
}
#carousel1 .slides li img,
#carousel2 .slides li img {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
#carousel2 .slides .flex-active-slide img,
#carousel2 .slides li img:hover,
#carousel1 .slides .flex-active-slide img,
.no-touch #carousel1 .slides li img:hover {
  opacity: 1;
}

/*-----------------------------------------------------------------------------------*/
/*	Static Sites
/*-----------------------------------------------------------------------------------*/
.static_content {
  flex-grow: 1;
  font-size: 14px;
  margin: 2em auto 6em;
  max-width: 50em;
  padding: 0 15px;
  line-height: 1.67;
  z-index: 0;

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.2;
    margin: 1.5em 0 0.5em;

    &:first-child {
      margin-top: 0;
    }
  }

  &__section_icon {
    color: #f9564e;
    display: block;
    margin: 40px auto 0;
    width: 50px;
  }
}

.faq_list {
  list-style: disclosure-closed;
  font-size: 16px;
  padding-left: 14px;

  &__item {
    list-style: inherit;

    &:hover {
      cursor: pointer;
    }

    &--open{
      list-style: disclosure-open;
    }

    &_question {
      margin-bottom: 0.5em;

      .faq_list__item--open & {
        color: #f9564e;
        font-weight: 700;
      }
    }

    &_answer {
      display: block;
      margin: 0;
      height: 0;
      overflow: hidden;

      .faq_list__item--open & {
        height: auto;
        margin-bottom: 1.5em;
      }
    }
  }
}

.contact_form {
  width: 50em;
  max-width: calc(100vw - 30px);
  margin-top: 2em;

  &__form_group {
    margin-bottom: 1em;

    &--flex {
      display: flex;
    }
  }

  &__label {
    font-weight: normal;
  }

  &__input {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 2px solid #e9e9e9;
    margin-bottom: 1em;
    padding: 0.5em 1em;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__textarea {
    -webkit-appearance: none;
    border-radius: 4px;
    border: 2px solid #e9e9e9;
    font-size: 14px;
    margin-bottom: 1em;
    overflow: auto;
    padding: 0.5em 1em;
    resize: vertical;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
  
  &__checkbox {
    width: 16px;
    height: 16px;
    margin-right: 0.5em !important;
    vertical-align: top;
    -webkit-appearance: checkbox;
  }

  &__submit {
    -webkit-appearance: none;
    -webkit-transition: all 0.3s ease-in-out;
    background-color: #f9564e;
    border-radius: 4px;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 0.5em 1em;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #ca3e37;
      color: #fff;
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*	Redirect
/*-----------------------------------------------------------------------------------*/
.redirect_block {
  width: 500px;
  max-width: 100%;
  margin: auto;
  padding-top: 100px;
  text-align: center;
}
.redirect_block img {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  max-width: 100%;
}
.redirect_block p:nth-of-type(1) {
  font-size: 18px;
  color: #333;
}
.redirect_block p {
  font-size: 12px;
  color: #bfbfbf;
  margin-bottom: 25px;
}
.redirect_block a {
  color: #666;
}
.no-touch .redirect_block a:hover {
  text-decoration: underline;
}

.spinner {
  margin: 25px auto;
  width: 30px;
  height: 30px;
  position: relative;
}

.container1 > div,
.container2 > div,
.container3 > div {
  width: 6px;
  height: 6px;
  background-color: #333;

  border-radius: 100%;
  position: absolute;
  -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
  animation: bouncedelay 1.2s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.spinner .spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container2 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.container3 {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.circle1 {
  top: 0;
  left: 0;
}
.circle2 {
  top: 0;
  right: 0;
}
.circle3 {
  right: 0;
  bottom: 0;
}
.circle4 {
  left: 0;
  bottom: 0;
}

.container2 .circle1 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.container3 .circle1 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.container1 .circle2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.container2 .circle2 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.container3 .circle2 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.container1 .circle3 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.container2 .circle3 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.container3 .circle3 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.container1 .circle4 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.container2 .circle4 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.container3 .circle4 {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*-----------------------------------------------------------------------------------*/
/*	PRODUCT PROMOTION
/*-----------------------------------------------------------------------------------*/
.tovar_details_header {
  margin: 0 15px;
}
.shop h3.pull-left {
  font-weight: 900;
}
.row.product_promotion {
  margin-bottom: 20px;
  text-align: center;
}
.img_product_promotion_wrapper {
  position: relative;
}
.img_product_promotion {
  width: 100%;
  padding: 30px 42px;
}
.product_promotion .article_sale {
  font-size: 20px;
  line-height: 34px;
}
.product_promotion h1 {
  margin: 0;
  font-size: 34px;
  line-height: 36px;
}
.product_promotion h2 {
  margin: 0;
  font-size: 26px;
  line-height: 28px;
}
.brand.product_promotion {
  padding: 38px 0px;
}
.brand.product_promotion h1 {
  padding-bottom: 10px;
}
.price.product_promotion {
  padding-bottom: 38px;
  font-size: 40px;
}
.price.product_promotion .old_price {
  font-size: 28px;
}
.price.product_promotion .article_price {
  line-height: 50px;
}
.panel-product-promotion {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  border-radius: 0;
  box-shadow: 0 0 0 0;
}
.panel-product-promotion .panel-body {
  overflow: hidden;
  word-wrap: break-word;
}
.btn-product-promotion {
  background: none repeat scroll 0 0 #cc3333;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border: 2px solid #333;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 38px;
}
.no-touch .btn-product-promotion:focus,
.btn-product-promotion:hover {
  border: 2px solid #333;
  background-color: #a02222;
}

.sharing {
  max-width: 80%;
  margin: 0 auto 20px;
}
.sharing p {
  color: #333;
  display: inline;
  font-weight: 900;
  margin-bottom: 0px;
  padding-top: 5px;
  text-transform: uppercase;
}
.sharing ul {
  display: inline;
}
.sharing ul li {
  display: inline-block;
  font-size: 24px;
  height: 34px;
  line-height: 30px;
  margin-left: 10px;
  width: 34px;
}
.sharing ul li a {
  color: #ccc;
  transition: all 0.3s ease-in-out 0s;
}
.no-touch .sharing ul li a:hover {
  color: #333;
}

.promotion_disclaimer {
  max-width: 80%;
  margin: -10px auto 0px;
  font-size: 9px;
}

#panel-hr {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 15px;
}
.panel-product-promotion.links {
  border: none;
}
.panel-product-promotion.links a {
  color: #666;
  display: inline-block;
  margin-right: 20px;
}
.no-touch .panel-product-promotion.links a:hover {
  text-decoration: underline;
}

.product_promotion hr {
  margin-left: 15px;
  margin-right: 15px;
}
.product_promotion .section_headline {
  margin-bottom: 30px;
  font-size: 18px;
  padding: 0px 15px;
}

.product_promotion .article_img {
  width: 182px;
  height: 233px;
}
.product_promotion .article_img_wrapper img {
  max-width: 182px;
  max-height: 233px;
}
.product_promotion .article_sale {
  font-size: 12px;
  line-height: 20px;
}

.product_promotion .shop_block {
  padding-top: 0px;
  padding-bottom: 40px;
}

.discussion {
  margin-bottom: 80px;
}

/*-----------------------------------------------------------------------------------*/
/*	Brands
/*-----------------------------------------------------------------------------------*/
.mood-image {
  display: block;
  margin-bottom: 20px;
  margin-top: -40px;
}
.mood-image__image {
  height: 600px;
  max-height: calc(80vh - 130px);
  object-fit: cover;
  object-position: top;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .mood-image__image {
    max-height: calc(50vh - 60px);
  }
}

ul.brand_index {
  background-color: #fff;
  border-bottom: 2px solid #8080802b;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 26px;
  position: sticky;
  top: 0;
  z-index: 1;
}
@media screen and (max-width: 1199px) {
  ul.brand_index {
    bottom: 0px;
    flex-direction: column;
    margin: 0;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 60px;
    border-left: 2px solid #8080802b;
  }
}
.brand_index li {
  line-height: 3;
  text-align: center;
}
@media screen and (max-width: 1199px) {
  .brand_index li {
    height: 48px;
    line-height: 48px;
    width: 60px;
  }
}
.brand_index a {
  font-size: 18px;
}
.brand_index a.is_visible {
  background-color: rgba(0, 0, 0, 0.46);
  color: #fff;
  font-weight: 700;
  text-decoration: underline;
}
@media screen and (min-width: 1199px) {
  .brand_index a {
    flex: 1 1 auto;
  }
  .brand_index a:hover {
    background-color: rgba(0, 0, 0, 0.46);
    color: #fff;
    font-weight: 700;
    text-decoration: underline;
  }
}
.brands_list_block {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1199px) {
  .brands_list_block {
    padding-right: 45px;
  }
}
.brands_list_container {
  flex: 1 1 70em;
}
@media screen and (min-width: 1199px) {
  .brands_list_container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }
}
.brand_list_row {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  position: relative;
}
@media screen and (min-width: 1199px) {
  .brand_list_row {
    flex-direction: column;
    flex: 1 0 25%;
  }
}
.brand_list_row > a {
  position: absolute;
  top: -60px;
}
.product_catalog_list {
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 12px;
}
@media screen and (max-width: 1199px) {
  .product_catalog_list {
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .product_catalog_list {
    column-count: 2;
  }
}
.brands_list_block__link {
  display: block;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1;
  margin: 0;
  text-transform: capitalize;
}
@media screen and (max-width: 1199px) {
  .brands_list_block__link {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1199px) {
  .brands_list_block__link {
    line-height: 2;
    margin: 0;
  }
}

/*-----------------------------------------------------------------------------------*/
/*	HOME TEASER
/*-----------------------------------------------------------------------------------*/
.home_teaser {
  margin-top: 15px;
}
.home_teaser .container {
  max-width: 1267px;
  background-repeat: no-repeat;
  background-size: cover;
}
.home_teaser .row {
  margin: 0px;
}
.home_teaser_description {
  padding: 80px 15px 50px;
}
.home_teaser_description a {
  display: block;
  background-color: #fff;
}
.no-touch .home_teaser_description a:hover {
  text-decoration: underline;
  color: #333;
}
.home_teaser_description p {
  margin: 0 0 10px;
  text-transform: uppercase;
  font-weight: 100;
  line-height: 50px;
  font-size: 50px;
  color: #333;
  text-align: center;
}
#homepage .home_teaser_description h2 {
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 25px;
  font-size: 25px;
  color: #333;
  text-align: center;
  background-color: transparent;
}
.home_teaser_description:last-child a {
  background-color: #000;
}
.no-touch .home_teaser_description:last-child a:hover {
  color: #fff;
}
.home_teaser_description:last-child p {
  color: #fff;
}
#homepage .home_teaser_description:last-child h2 {
  color: #fff;
}

/*-----------------------------------------------------------------------------------*/
/*	Category Teaser
/*-----------------------------------------------------------------------------------*/
.category-teaser {
  display: flex;
  height: 600px;
  margin-bottom: 64px;
}
@media screen and (min-width: 1199px) {
  .category-teaser {
    max-height: calc(100vh - 237px);
  }
}
@media screen and (max-width: 1199px) {
  .category-teaser {
    height: 400px;
    margin-bottom: 25px;
    max-height: calc(100vh - 346px);
  }
}
@media screen and (max-width: 768px) {
  .category-teaser {
    height: 368px;
    margin-bottom: 25px;
    max-height: calc(100vh - 346px);
  }
}
@media screen and (max-width: 420px) {
  .category-teaser {
    flex-direction: column;
    height: 900px;
    margin-bottom: 25px;
    max-height: none;
  }
}
.category-teaser__category-container {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  flex: 1 0 auto;
  margin-right: 10px;
}
.category-teaser__category-container:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 420px) {
  .category-teaser__category-container {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .category-teaser__category-container:last-of-type {
    margin-bottom: 0;
  }
}
.category-teaser__category-link {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}
.category-teaser__category-link:hover .category-teaser__category-cta {
  background-color: rgba(0, 0, 0, 1);
  line-height: 3.5;
}
.category-teaser__category-cta {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  color: #fff;
  font-size: 20px;
  left: 0;
  line-height: 2.5;
  position: absolute;
  text-align: center;
  transition: line-height 0.15s ease-in-out, background-color 0.15s ease-in-out;
  width: 100%;
}

/*-----------------------------------------------------------------------------------*/
/*	BREADCRUMBS/DEEPLINKS
/*-----------------------------------------------------------------------------------*/
.breadcrumb__container {
  align-items: flex-start;
  background-color: hsl(0, 0%, 96%);
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  flex: 0 0 calc(100% - 30px);
  margin: 0px 15px 26px;
  max-width: calc(100% - 30px);
  padding: 10px;
  position: relative;
}
.breadcrumb__list {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  margin: -5px 0 -10px;
  overflow: auto;
  user-select: none;
  width: 100%;
}
@media screen and (min-width: 1199px) {
  .breadcrumb__list {
    margin: -1px 0px 0px;
  }
}
@media screen and (max-width: 1199px) {
  .breadcrumb__list--brand-logo {
    margin: -14px 0 24px;
  }
}
.breadcrumb__item {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  height: 30px;
}
.breadcrumb__item_icon_sep {
    height: 9px;
    transform: rotateZ(-90deg);
    width: 22px;

    path {
      stroke-width: 8px;
      stroke: currentColor;
    }

    @media screen and (max-width: 600px) {
      height: 10px;
      width: 16px;
    }
}
.breadcrumb__link {
  color: #595959;
  font-size: 11px;
  white-space: nowrap;

  &:hover {
    color: #000;
  }
}
@media screen and (min-width: 600px) {
  .breadcrumb__link {
    font-size: 12px;
  }
}

/* DeepLinks */
.deeplinks__container {
  display: flex;
  flex: 1 0 auto;
  max-width: calc(50% - 75px / 2);
  overflow: hidden;
  position: relative;
}
.deeplinks__container--full-width {
  max-width: 100%;
}
@media screen and (max-width: 800px) {
  .deeplinks__container {
    max-width: 100%;
  }
}
.deeplinks__spacer {
  flex: 0 0 75px;
}
@media screen and (max-width: 800px) {
  .deeplinks__spacer {
    display: none;
  }
}
.deeplinks__list {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  width: 100%;
}
.deeplinks__list::-webkit-scrollbar {
  display: none;
}
.deeplinks__cursor {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  max-height: 50px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.1s;
  width: 40px;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  .deeplinks__cursor {
    display: none;
  }
}
.deeplinks__cursor:hover {
  cursor: pointer;
}
.deeplinks__cursor--visible {
  opacity: 1;
}
.deeplinks__cursor--left {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsPSJub25lIiBkPSJNLTEtMWg1ODJ2NDAySC0xeiIvPgogIDxnPgogICAgPHBhdGggZmlsbD0iIzZiNmI2YiIgZD0iTTM1Mi4yODkzNjAyOSAzOTYuNDAxOTkxMDdsLTIwLjY2MDAwMjA1IDE5LjQ0MjU5MDIzLTE3MS42MjkxNTQ2NS0xNTkuNjQ2ODcwNTRMMzMwLjk3MDMyMjM4IDk1Ljg0NTI1OTk0bDIwLjczOTcwNDIgMTkuMjU3MzI3Ny0xNTAuMjA5OTExIDE0MS4wMDk2NTQ0eiIvPgogIDwvZz4KPC9zdmc+Cg==");
  left: -12px;
}
.deeplinks__cursor--right {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBmaWxsPSJub25lIiBkPSJNLTEtMWg1ODJ2NDAySC0xeiIvPgogIDxnPgogICAgPHBhdGggZmlsbD0iIzZiNmI2YiIgZD0iTTE2MCAxMTUuNEwxODAuNyA5NiAzNTIgMjU2IDE4MC43IDQxNiAxNjAgMzk2LjcgMzEwLjUgMjU2eiIvPgogIDwvZz4KPC9zdmc+Cg==");
  right: -12px;
}
.deeplinks__cursor--left.deeplinks__cursor--visible::before {
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  );
  content: "";
  height: 100%;
  position: absolute;
  right: -20px;
  width: 20px;
  z-index: 1;
}
.deeplinks__cursor--right.deeplinks__cursor--visible::before {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  );
  content: "";
  height: 100%;
  left: -20px;
  position: absolute;
  width: 20px;
  z-index: 1;
}
.deeplinks__link {
  background-color: #9999990a;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  color: #666;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin: 10px 14px 10px 0;
  padding: 7px;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;
}
.deeplinks__link:hover {
  border: 1px solid rgb(249, 86, 78);
}

/* Page Header */
.page_header .container {
  margin-top: 6rem;
  margin-bottom: 21px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e9e9e9;
}

.page_header a {
  display: block;
  font-weight: 300;
  font-size: 13px;
  color: #666;
}
.no-touch .page_header a:hover {
  color: #333;
}
.page_header a i {
  margin-left: 10px;
  display: inline-block;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 19px;
  font-size: 17px;
  color: #999;
  border: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.no-touch .page_header a:hover i {
  color: #333;
  border-color: #333;
}

/*-----------------------------------------------------------------------------------*/
/*	SIDEBAR
/*-----------------------------------------------------------------------------------*/
/* __________ Widget Search __________ */

/*-----------------------------------------------------------------------------------*/
/*	SHOP BLOCK
/*-----------------------------------------------------------------------------------*/

/* SEARCH MOBILE*/
.search_mobile_container {
  display: none;
}

/* SORTING TOVAR PANEL */
.sort_filter_mobile {
  display: none;
}
.sort_filter_mobile_box {
  display: none;
}
#mobile_hr {
  display: none;
}
.sorting_options {
  align-items: baseline;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  max-width: 100%;
  padding: 13px 0;
}
.sorting_options--collapse {
  margin-top: -35.5px;
}
@media screen and (max-width: 1199px) {
  .sorting_options--collapse {
    margin-top: 0;
  }
}
.sorting_options p {
  display: inline-block;
  font-size: 16px;
  margin: 0 9px 0 0;
  padding-right: 9px;
  position: relative;
  text-transform: initial;
}
.sorting_options p:before {
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  width: 1px;
  height: 13px;
  border-right: 1px dotted #999;
}
.sorting_bottom {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
.sorting_top {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  margin-left: auto;
  white-space: nowrap;
}
p.alternative_products {
  margin-top: 15px;
  text-transform: none;
}
p.alternative_products:before {
  border: 0;
}
.count_tovar_items {
  display: inline-block;
  vertical-align: top;
}

/* Tovar Sorting */
.product_sort {
  align-items: center;
  display: flex;
  position: relative;

  p {
    font-size: 16px;
    margin: 0;
    padding-right: 6px;
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

/* Tovar Pagination */
.sorting_options ul.pagination {
  float: right;
}
.select {
  border-right: 20px solid transparent !important;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  outline: none;
}
.select__icon {
  cursor: pointer;
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: auto;
}
.select__icon--up {
  transform: rotate(180deg);
}

/* Tovar Sizes */
#toggle-sizes {
  float: right;
}
#toggle-sizes a {
  display: inline-block;
  margin-left: 6px;
  line-height: 20px;
  font-size: 18px;
  color: #ccc;
}
.no-touch #toggle-sizes a.active,
#toggle-sizes a:hover {
  color: #333;
}

/* __________ Category Info __________ */
.category-info {
  border: 2px solid #ccc;
  margin: 0;
  padding: 14px 18px 7px;
}
@media screen and (min-width: 1199px) {
  .category-info {
    column-count: 2;
  }
}
.category-info p {
  hyphens: auto;
  max-width: 70ch;
}

/* __________ Shop Section Sizes __________ */
.shop_block {
  padding-top: 10px;
  margin-left: -16px;
  margin-right: -16px;
}
#homepage .shop_block {
  padding-top: 15px;
}

.tovar_content {
  display: none;
}
.variable-sizes .tovar_content {
  display: block;
}

.variable-sizes .tovar_img_wrapper {
  height: 275px !important;
}

.shop_block.variable-sizes .tovar_wrapper {
  position: relative;
  width: 450px;
}

.shop_block.variable-sizes .tovar_item {
  height: 100%;
}

.shop_block.variable-sizes .tovar_img {
  overflow: visible;
  float: left;
  width: 200px;
}

.shop_block.variable-sizes .tovar_description {
  float: right;
  width: 220px;
  margin: 0 0 15px;
  padding: 6px 0 16px;
  border-bottom: 1px solid #e9e9e9;
}
.shop_block.variable-sizes .tovar_description .tovar_title {
  float: none;
  padding-bottom: 15px;
}
.shop_block.variable-sizes .tovar_description .tovar_price {
  float: none;
}

.shop_block.variable-sizes .tovar_item_btns {
  left: 100%;
  bottom: 0;
  width: 100%;
  opacity: 1;
}
.shop_block.variable-sizes .tovar_item_btns .tovar_view {
  padding-left: 15px;
  padding-right: 15px;
}
.shop_block.variable-sizes .tovar_item_btns .tovar_view span {
  display: none;
}

.sk-folding-cube {
  margin: 80px auto 20px;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

#loading_products p {
  text-align: center;
  margin-bottom: 100px;
}

#sorry_no_products {
  padding: 0 15px;
}
#sorry_no_products p:first-child {
  font-size: 28px;
  font-weight: 900;
  line-height: 30px;
}
#sorry_no_products p {
  font-size: 13px;
  color: #333;
}

/* Pagination */
.pagination {
  margin: 0;
}
.pagination_bottom {
  width: 100%;
  text-align: center;
}
.pagination li {
  display: inline-block;
  vertical-align: top;
  margin: 0 7px 0 0;
}
.pagination li a {
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  font-weight: 400;
  text-align: center;
  line-height: 38px;
  font-size: 11px;
  color: #666;
  border: 1px solid #e9e9e9;
  border-radius: 0 !important;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  -webkit-transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.pagination li a:hover,
.pagination li.active a {
  font-weight: 900;
  line-height: 36px;
  font-size: 16px;
  color: #333;
  background: none;
  border: 2px solid #333;
}
.no-touch .pagination li.active a:hover {
  color: #fff;
  border-color: #333;
  background-color: #333;
}
.no-touch .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #fff;
  border-color: #333;
  background-color: #333;
}

/*-----------------------------------------------------------------------------------*/
/*	ARTICLES
/*-----------------------------------------------------------------------------------*/
.articles_block {
  padding-top: 9px;
}

.article_image {
  position: relative;
}

.article_large .article_image {
  float: left;
  margin: 0 30px 20px 0;
}
.article_image:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: -1px;
  bottom: 0;
  opacity: 0;
  background-color: #fff;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
}
.no-touch .article_item:hover .article_image:before {
  opacity: 0.65;
}
.article_date {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 60px;
  padding-top: 14px;
  text-align: center;
  font-weight: 300;
  line-height: 20px;
  font-size: 22px;
  color: #fff;
  background-color: #333;
}
.article_date span {
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 15px;
  font-size: 12px;
}
.article_image {
  display: block;
  margin: 0 0 23px;
}
.article_title {
  display: block;
  margin-bottom: 18px;
  line-height: 36px;
  font-size: 30px;
}

.article_large .article_title {
  font-size: 34px;
}
.article_small .article_title {
  line-height: 30px;
  font-size: 24px;
}
.article_item .post_meta li i {
  padding-right: 3px;
}

/* __________ Article Single __________ */
.article_single .article_image {
  float: left;
  margin-bottom: 40px;
  padding-left: 30px;
}
.article_single .article_date {
  top: 30px;
}
.article_single_caption {
  position: relative;
  z-index: 2;
  float: right;
  display: table;
  margin: 30px 0 0 -30px;
  padding: 0 28px;
  width: 400px;
  height: 360px;
  background-color: #333;
}
.article_single_caption p {
  display: table-cell;
  vertical-align: middle;
  line-height: 44px;
  font-size: 36px;
  color: #fff;
}

.article_single_content h3 {
  float: left;
  width: 775px;
  margin-bottom: 34px;
  padding-left: 30px;
  text-transform: none;
  line-height: 33px;
  font-size: 20px;
}
.article_single_content blockquote {
  float: left;
  width: 300px;
}

.article_single_content p {
  margin-bottom: 24px;
  line-height: 24px;
  font-size: 14px;
}

.article_single_content .post_meta {
  padding-bottom: 44px;
}

.article_single_content .shared_tags_block {
  padding-bottom: 29px;
  margin: 0;
  border: 0;
}

/*-----------------------------------------------------------------------------------*/
/*	PRODUCT CATALOG LIST
/*-----------------------------------------------------------------------------------*/
.product_catalog_block .container {
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9;
}
.product_catalog_block.last .container {
  border-bottom: 0;
}

.product_catalog_item img {
  float: left;
  margin: 9px 30px 10px 0;
}
.product_catalog_title {
  margin: 0 0 5px;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 47px;
  font-size: 40px;
  color: #333;
}
.product_catalog_list li {
  position: relative;
  padding: 10px 15px 0 12px;
}
.product_catalog_list li:before {
  content: "°";
  position: absolute;
  left: 0;
  top: 14px;
  font-size: 16px;
  color: #ccc;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.no-touch .product_catalog_list li:hover:before {
  color: #333;
}
.product_catalog_list li a {
  text-transform: uppercase;
  color: #666;
}
.no-touch .product_catalog_list li a:hover {
  color: #333;
}

.product_catalog_item .product_catalog_list {
  padding-left: 300px;
}

/* __________ Brands List __________ */
.brand_letter {
  border-bottom: 2px solid #333;
  color: #333;
  font-size: 70px;
  font-weight: 300;
  line-height: 111px;
  min-width: 150px;
  text-transform: uppercase;
}
@media screen and (min-width: 1199px) {
  .brand_letter {
    margin-right: 40px;
  }
}
.brands_list_block__brand {
  padding: 0 !important;
  padding-right: 15px !important;
}
.brands_list_block__brand:before {
  content: "" !important;
}

/*-----------------------------------------------------------------------------------*/
/*	FOOTER
/*-----------------------------------------------------------------------------------*/
footer {
  position: relative;
  padding: 50px 0 0;
  line-height: 2;
  font-size: 14px;
  color: #fff;
  background-color: #232f3f;
}

.footer_nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;

  &__column {
    flex-basis: 25%;
    margin-bottom: 50px;
    padding-right: 24px;

    @media screen and (max-width: 900px) {
      flex-basis: 33%;
    }

    @media screen and (max-width: 700px) {
      flex-basis: 50%;
    }

    @media screen and (max-width: 440px) {
      flex-basis: 100%;
    }
  }

  &__logo {
    background-color: #fff;
    border-radius: 18px;
    margin-bottom: 10px;
    max-width: 200px;
    padding: 16px;
    width: 100%;
  }

  &__text {
    margin: 0;
  }

  &__title {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__link {
    color: #fff;

    &:hover {
      color: #f67871;
    }
  }
}


/*-----------------------------------------------------------------------------------*/
/*	FORM
/*-----------------------------------------------------------------------------------*/
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  color: #666;
  border: 2px solid #e9e9e9;
}
input[type="submit"] {
  float: right;
  margin: 0;
  padding: 10px 52px;
  text-transform: uppercase;
  font-weight: 900;
  font-style: normal;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 0;
  background: #434343;
  border: 0;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.no-touch input[type="submit"]:hover {
  background-color: #242424;
}

/*-----------------------------------------------------------------------------------*/
/*	JCAROUSEL STYLES
/*-----------------------------------------------------------------------------------*/
.jcarousel-wrapper {
  position: relative;
}
.jcarousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.jcarousel ul {
  width: 20000em;
  position: relative;
}
.jcarousel li {
  float: left;
  width: 370px;
  margin-right: 30px;
}

.lcarousel-wrapper {
  position: relative;
}
.jCarousel_pagination a {
  position: absolute;
  z-index: 100;
  top: -50px;
  display: block;
  width: 24px;
  height: 24px;
  font-family: fontello;
  text-align: center;
  line-height: 19px;
  font-size: 17px;
  color: #999;
  border: 2px solid #ccc;
}
.jcarousel-control-prev {
  right: 29px;
}
.jcarousel-control-next {
  right: 0;
}
.no-touch .jCarousel_pagination a:hover {
  color: #333;
  border-color: #333;
}

.jCarousel_pagination a.inactive {
  cursor: default;
  color: #999 !important;
  border-color: #ccc !important;
}

/* __________ Best Products __________ */
.best_products .jcarousel li {
  width: 270px;
}

/* __________ Best Products __________ */
.popular_articles .jcarousel li {
  width: 370px;
}

/*-----------------------------------------------------------------------------------*/
/*	OUIBOUNCE MODAL
/*-----------------------------------------------------------------------------------*/

#ouibounce-modal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}
#ouibounce-modal .underlay {
  animation: 0.2s ease 0s normal none 1 running fadein;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
}
#ouibounce-modal .ouibounce-modal {
  animation: 0.2s ease 0s normal none 1 running popin;
  background-color: #fff;
  border-radius: 4px;
  bottom: 0;
  height: 400px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 600px;
  z-index: 5;
}
#ouibounce-modal .ouibounce-modal-body {
  font-size: 0.9em;
  padding: 35px;
  text-align: center;
}
#ouibounce-modal i {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
}
#ouibounce-modal p {
  font-size: 25px;
  color: #344a5f;
  line-height: 1.3em;
  text-align: center;
}
#ouibounce-modal a {
  font-size: 25px;
  color: #e47911;
  font-weight: 900;
  text-decoration: underline;
  text-align: center;
  text-transform: uppercase;
}
#ouibounce-modal p > a {
  text-transform: none;
}
#ouibounce-modal .ouibounce-modal-footer {
  bottom: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
}
#ouibounce-modal .ouibounce-modal-footer p {
  border-bottom: 1px solid #344a5f;
  cursor: pointer;
  display: inline;
  font-size: 11px;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popin {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  85% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*-----------------------------------------------------------------------------------*/
/*	RESPONSIVE STYLES
/*-----------------------------------------------------------------------------------*/

@media screen and (-webkit-min-device-pixel-ratio: 0) {
}

@media screen and (max-width: 1024px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
  .respond_clear_1024 {
    clear: both;
    height: 0;
    font-size: 0;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SLIDER
/*-----------------------------------------------------------------------------------*/

  /* __________ Menu __________ */
  .navmenu li a {
    padding-left: 12px;
    padding-right: 12px;
  }

  /* __________ Tovar Item __________ */
  .tovar_item_btns a {
    margin: 0 0 0 2px;
  }
  .tovar_view {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }

  /* __________ Brands Carousel __________ */
  .brands_carousel .jcarousel {
    width: 882px;
  }

  /* __________ Recent Posts __________ */
  .recent_post_img {
    width: 200px;
    margin-right: 15px;
  }
  .recent_post_title {
    font-size: 18px;
    line-height: 22px;
  }

  .home_teaser_description p {
    line-height: 46px;
    font-size: 46px;
  }
  .home_teaser_description span {
    line-height: 19px;
    font-size: 19px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SHOES
/*-----------------------------------------------------------------------------------*/
  .shoes_page {
    min-height: 740px;
  }
  .shoes_page_description {
    padding: 150px 0 70px;
  }

  /* __________ Popular Articles carousel __________ */
  .popular_articles .jcarousel li {
    width: 303px;
  }
  .article_title {
    font-size: 24px;
    line-height: 30px;
  }
  .post_meta li {
    font-size: 10px;
    padding-right: 10px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	SHOP
/*-----------------------------------------------------------------------------------*/
  .breadcrumb.women,
  .breadcrumb.men {
    padding: 120px 0 40px;
  }
  .breadcrumb h2 {
    font-size: 90px;
    line-height: 90px;
  }

  /* __________ Tovar Item Sizes __________ */
  .shop_block.variable-sizes .tovar_wrapper {
    width: 374px;
  }
  .shop_block.variable-sizes .tovar_img {
    width: 150px;
  }
  .shop_block.variable-sizes .tovar_description {
    width: 193px;
  }
  .shop_block.variable-sizes .tovar_item_btns {
    width: 198px;
  }

  /* __________ Product Details __________ */
  .tovar_view_fotos {
    width: 430px;
  }
  .tovar_view_description {
    width: 255px;
  }
  .tovar_view_title {
    font-size: 26px;
    line-height: 28px;
  }
  .tovar_view_btn .add_bag {
    padding: 13px 18px;
  }

  /* __________ Love List __________ */
  table tr.cart_item td.product-name {
    width: 200px;
  }
  table tr.cart_item td.product-add-bag {
    width: 160px;
  }

  /* __________ Shopping Bag __________ */
  .widget_bag_totals .btn {
    padding-left: 5px;
    padding-right: 5px;
  }

  /* __________ Product Catalog __________ */
  .product_catalog_item img {
    width: 200px;
  }
  .product_catalog_item .product_catalog_list {
    padding-left: 230px;
  }
  .product_catalog_list li {
    padding-top: 3px;
  }
  .product_catalog_list li:before {
    top: 7px;
  }

  /* __________ Brands List __________ */
  .brand_letter {
    font-size: 80px;
    line-height: 80px;
    margin-right: 20px;
  }

  /* __________ Catalog Search Form __________ */
  .top_search_form {
    width: 329px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	ARTICLES
/*-----------------------------------------------------------------------------------*/
  .article_large .article_image {
    width: 580px;
  }
  .article_large .article_title {
    line-height: 34px;
  }
  .article_small .article_title {
    font-size: 18px;
    line-height: 22px;
  }

  /* __________ Single Article __________ */
  .article_single .article_image {
    width: 630px;
  }
  .article_single_caption {
    width: 369px;
    height: 283px;
  }
  .article_single_caption p {
    font-size: 32px;
    line-height: 36px;
  }
  .article_single_content h3 {
    width: 630px;
    font-size: 17px;
    line-height: 26px;
  }
  .article_single_content blockquote {
    width: 269px;
  }

  .flexslider.article_slider {
    width: 500px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	FOOTER
/*-----------------------------------------------------------------------------------*/
  footer .newsletter_btn {
    width: 100px;
  }
  footer .newsletter_form input[type="text"] {
    width: 194px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	PAGINATION TOP
/*-----------------------------------------------------------------------------------*/
  .sorting_top ul.pagination {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .respond_clear_768 {
    clear: both;
    height: 0;
    font-size: 0;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SLIDER
/*-----------------------------------------------------------------------------------*/

  /* __________ Menu __________ */
  .navmenu li a {
    padding-left: 11px;
    padding-right: 11px;
  }

  .love_list .love_list_btn p,
  .shopping_bag .shopping_bag_btn p {
    display: none;
  }
  .shopping_bag .shopping_bag_btn,
  .love_list .love_list_btn {
    padding: 20px 10px;
  }
  .cart {
    left: auto;
    right: 0;
  }

  .navmenu li.sub-menu ul.megamenu_col3 {
    width: 355px;
  }
  .navmenu li.sub-menu ul li a {
    font-size: 10px;
    line-height: 15px;
    padding-bottom: 5px;
  }
  .navmenu li ul.mega_menu li.col {
    padding-left: 10px;
    width: 116px;
  }

  /* __________ Tovar Item __________ */
  .tovar_item_btns a {
    margin: 0 0 0 2px;
  }
  .tovar_view {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }

  /* __________ Brands Carousel __________ */
  .brands_carousel .jcarousel {
    width: 662px;
  }

  /* __________ Recent Posts __________ */
  .recent_post_img {
    width: 200px;
    margin-right: 15px;
  }
  .recent_post_title {
    font-size: 18px;
    line-height: 22px;
  }

  /* __________ Services __________ */
  .service_item i {
    width: 28px;
    font-size: 30px;
  }
  .service_item p {
    font-size: 14px;
  }
  .service_item span {
    font-size: 11px;
    line-height: 17px;
  }

  .service_item.type2 p {
    font-size: 20px;
    line-height: 20px;
  }
  .service_item.type2 span {
    padding: 0;
  }

  /* __________ Banners __________ */
  .tovar_wrapper .banner {
    display: inline-block;
  }

  .home_teaser_description p {
    line-height: 35px;
    font-size: 35px;
  }
  .home_teaser_description span {
    line-height: 15px;
    font-size: 15px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME MEN PROMO
/*-----------------------------------------------------------------------------------*/

  /* __________ Banners __________ */
  .top_sale {
    min-height: 600px;
  }

  .top_sale_description {
    padding: 170px 0 50px;
  }
  .top_sale_description p {
    font-size: 60px;
    line-height: 60px;
  }
  .top_sale_description span {
    font-size: 24px;
    line-height: 28px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME VIDEO
/*-----------------------------------------------------------------------------------*/
  .fashion_week {
    min-height: 620px;
  }

  .fashion_week_description p {
    font-size: 60px;
    line-height: 60px;
  }
  .fashion_week_description span {
    font-size: 24px;
    line-height: 28px;
  }

  .video_wrapper {
    width: 550px;
    height: 272px;
  }
  .video_wrapper iframe {
    height: 272px;
  }

  /* __________ Best Products Carousel __________ */
  .best_products .jcarousel li {
    width: 220px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SALE
/*-----------------------------------------------------------------------------------*/
  .sale_caption1 .title1 {
    font-size: 60px;
    line-height: 60px;
  }
  .sale_caption1 .title2 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 25px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SHOES
/*-----------------------------------------------------------------------------------*/
  .shoes_page {
    min-height: 460px;
  }
  .shoes_page_description {
    padding: 140px 0 30px;
  }
  .shoes_page_description p {
    font-size: 60px;
    line-height: 60px;
  }
  .shoes_page_description p b {
    font-size: 70px;
    line-height: 70px;
  }
  .shoes_page_description span {
    font-size: 40px;
    line-height: 40px;
    padding-bottom: 40px;
  }

  /* __________ Popular Articles carousel __________ */
  .popular_articles .jcarousel li {
    width: 230px;
  }
  .article_title {
    font-size: 18px;
    line-height: 20px;
  }
  .post_meta li {
    font-size: 10px;
    padding-right: 10px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	SHOP
/*-----------------------------------------------------------------------------------*/
  .breadcrumb.women,
  .breadcrumb.men {
    padding: 120px 0 40px;
  }
  .breadcrumb h2 {
    font-size: 90px;
    line-height: 90px;
  }

  /* __________ Sidebar __________ */
  #price-range input {
    width: 80%;
  }

  .widget_newsletter input[type="text"] {
    font-size: 8px;
  }
  .widget_newsletter input[type="submit"] {
    font-size: 9px;
  }

  .tovar_title {
    font-size: 12px;
    width: 77%;
  }

  .widget_popular_post_img {
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .widget_popular_post_img img {
    width: 100%;
  }

  /* __________ New Arrivals __________ */
  .new_arrivals .jcarousel li {
    width: 165px;
  }

  /* __________ Tovar Item Sizes __________ */
  .product_sort {
    width: 60%;
  }

  .shop_block.variable-sizes .tovar_wrapper {
    width: 100%;
    padding-right: 0;
  }
  .shop_block.variable-sizes .tovar_img {
    width: 200px;
  }
  .shop_block.variable-sizes .tovar_description {
    width: 369px;
  }
  .shop_block.variable-sizes .tovar_item_btns {
    width: 180px;
  }

  .tovar_description {
    min-height: 60px;
  }

  .shop .top_sale_banners .banner {
    width: 100%;
  }

  /* __________ Product Details __________ */
  .tovar_view_fotos {
    width: 430px;
  }
  .tovar_view_description {
    width: 289px;
  }
  #modal-body .tovar_view_description {
    width: 227px;
  }
  .tovar_view_title {
    font-size: 26px;
    line-height: 28px;
  }
  .tovar_view_btn .add_bag {
    padding: 13px 18px;
  }

  .tovar_items_small li {
    display: inline-block;
    width: 49%;
    padding-right: 40px;
  }
  #sidebar .tovar_items_small li {
    width: 100%;
    float: none;
    padding-right: 0;
  }

  /* __________ Modal __________ */
  #tovar_content {
    width: 750px;
  }

  /* __________ Love List __________ */
  table tr.cart_item td.product-name {
    width: 200px;
  }
  table tr.cart_item td.product-add-bag {
    width: 160px;
  }

  /* __________ Shopping Bag __________ */
  .widget_bag_totals .btn {
    padding-left: 5px;
    padding-right: 5px;
  }

  /* __________ Product Catalog __________ */
  .product_catalog_item img {
    width: 140px;
    margin-right: 20px;
  }
  .product_catalog_item .product_catalog_list {
    padding-left: 160px;
  }
  .product_catalog_list li {
    padding-top: 3px;
  }
  .product_catalog_list li:before {
    top: 7px;
  }

  /* __________ Brands List __________ */
  .brand_letter {
    font-size: 60px;
    line-height: 60px;
    margin-right: 10px;
    width: 55px;
  }
  .brands_list_block .product_catalog_list li {
    padding-top: 0;
  }
  .brands_list_block .product_catalog_list li:before {
    top: 4px;
  }
  .brands_list_block .product_catalog_list li a {
    font-size: 10px;
  }

  /* __________ Catalog Search Form __________ */
  .top_search_form {
    width: 329px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	ARTICLES
/*-----------------------------------------------------------------------------------*/
  .article_large .article_image {
    width: 430px;
  }
  .article_large .article_title {
    line-height: 22px;
    font-size: 20px;
  }
  .article_small .article_title {
    font-size: 18px;
    line-height: 22px;
  }

  /* __________ Single Article __________ */
  .article_single .article_image {
    width: 500px;
  }
  .article_single_caption {
    width: 279px;
    height: 209px;
  }
  .article_single_caption p {
    font-size: 22px;
    line-height: 26px;
  }
  .article_single_content h3 {
    width: 500px;
    font-size: 17px;
    line-height: 22px;
  }
  .article_single_content blockquote {
    width: 179px;
  }

  .flexslider.article_slider {
    width: 370px;
    margin: 0 20px 10px 0;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	FOOTER
/*-----------------------------------------------------------------------------------*/
  footer .newsletter_btn {
    width: 20%;
  }
  footer .newsletter_form input[type="text"] {
    width: 78%;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	PAGINATION TOP
/*-----------------------------------------------------------------------------------*/
  .sorting_top ul.pagination {
    display: none;
  }
}

@media (max-width: 1199px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .respond_clear_480 {
    clear: both;
    height: 0;
    font-size: 0;
  }

  .menu_toggler {
    margin-right: 15px;
    display: block;
  }

  .menu_block div.container:nth-child(1) {
    height: auto;
    background-color: #fcfcfc;
    border-bottom: 2px solid #e9e9e9;
    padding-bottom: 5px;
    padding-top: 5px;
    min-height: 58px;
    display: flex;
  }

  .menu_block div.container:nth-child(2) {
    margin: 0;
  }

  .menu_block .navmenu {
    display: none;
  }

  .menu_block div.container:nth-child(3) {
    display: none;
  }

  nav#mobile-menu {
    display: block;
  }

  .logo {
    pointer-events: initial;
    position: initial;
    width: 100%;
    padding: 0 60px 0 0;
    display: block;
  }
  .logo a {
    display: flex;
    align-items: center;
    height: 100%;
  }
  img#logo {
    max-width: 100%;
    position: initial;
    margin: auto;
  }

  .breadcrumb {
    display: none;
  }

  .top_search_form {
    display: none;
  }

  #io_sidebar_check {
    display: none;
  }

  /* __________ Search Mobile __________ */
  .search_mobile_container {
    display: block;
    line-height: 48px;
    position: relative;
  }
  #search_mobile {
    display: flex;
  }
  #search_mobile_input {
    border-bottom-left-radius: 2px;
    border-right: none;
    border-top-left-radius: 2px;
    border: 2px solid #333;
    font-size: 16px;
    height: 52px;
    padding: 0px 10px;
    width: 100%;
  }
  .search_mobile_container button {
    align-items: center;
    background-color: #fff;
    border-bottom-right-radius: 2px;
    border-radius: 0;
    border-top-right-radius: 2px;
    border: 2px solid #333;
    display: flex;
    font-size: 20px;
    height: 52px;
    justify-content: center;
    width: 58px;
  }
  .search_mobile_container button:before {
    content: "\e801";
    font-family: Fontello;
    font-size: inherit;
  }

  /* __________ Sort/Filter Mobile __________ */
  .sort_filter_mobile_box {
    border-radius: 2px;
    border: 1px solid #666;
    color: #666;
    display: none;
    font-size: 13px;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-transform: uppercase;
  }
  .sort_filter_mobile_box li {
    font-size: 16px;
    line-height: 48px;
    position: relative;
    border-bottom: 1px dotted #242424;
    padding-right: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sort_filter_mobile_box li:last-child {
    border: none;
  }
  .sort_filter_mobile_box li:before {
    content: "\f096";
    font-family: Fontello;
    line-height: inherit;
    font-size: 26px;
    right: 10px;
    position: absolute;
  }
  .sort_filter_mobile_box li.active:before {
    content: "\e803";
  }

  .sort_mobile_box p {
    line-height: 48px;
    margin: 0;
    padding-left: 10px;
    border-bottom: 1px dotted #242424;
  }
  .sort_mobile_box p:last-child {
    border: none;
  }
  .sort_mobile_box p.active {
    background-color: #242424;
    color: #fff;
    font-weight: 700;
  }
  .no-touch .sort_filter_mobile_box p:hover {
    cursor: pointer;
  }
  .filter_mobile_box p.menu_heading {
    line-height: 48px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px dotted #242424;
  }
  .mobile_select_box:last-child p.menu_heading {
    border: none;
  }
  .mobile_select_box.active p.menu_heading {
    background-color: #242424;
    color: #fff;
    font-weight: 900;
    border: none;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
  }
  .mobile_select_box p.menu_heading:after {
    font-family: fontello;
    content: "\e804";
    float: right;
  }
  .mobile_select_box.active p.menu_heading:after {
    content: "\e805";
  }
  .mobile_select_box {
    position: relative;
  }
  .mobile_select_box.active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 50;
  }
  .mobile_select_box .mobile_filters_search {
    display: none;
  }
  .mobile_select_box.active .mobile_filters_search {
    display: block;
    position: fixed;
    top: 48px;
    left: 0px;
    width: 100%;
  }
  .mobile_filters_search:before {
    content: "\e801";
    font-family: Fontello;
    position: absolute;
    left: 10px;
    font-size: 26px;
    line-height: 48px;
  }
  .mobile_select_box input[type="search"] {
    line-height: 48px;
    border: none;
    border-bottom: 2px solid #242424;
    font-size: 16px;
    padding: 0px 10px 0px 48px;
    width: 100%;
  }
  .mobile_select {
    display: none;
    cursor: pointer;
    width: 100%;
    padding-left: 10px;
    line-height: 40px;
    height: calc(100vh - 48px - 50px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    margin-top: 48px;
  }
  .mobile_select_box.active .mobile_select.list_active {
    display: block;
  }
  .mobile_select.select_brands {
    height: calc(100vh - 48px - 50px * 2);
    margin-top: 98px;
  }

  .mobile-btn-row {
    background: #fff;
    display: none;
    margin: 0;
    border-top: 2px solid #242424;
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
  .mobile_select_box.active .mobile-btn-row {
    display: block;
  }
  .mobile-btn-row .btn-container {
    padding: 0;
  }
  .mobile-btn-row .btn-container .btn {
    color: #242424;
    width: 100%;
    border: none;
    border-right: 2px solid #242424;
    line-height: 48px;
    padding: 0;
  }
  .mobile-btn-row .btn-container:last-child .btn {
    border: none;
  }

  .selection .btn {
    padding: 13px 0px;
  }

  #mobile_hr {
    display: block;
    margin: 20px 0;
  }

  /* __________ Scroll Top __________ */
  #scroll_top_mobile {
    bottom: 6px;
    left: calc(100vw - 50% - 24px);
    font-size: 48px;
    color: rgba(51, 51, 51, 0.15);
    display: block;
    text-align: center;
  }

  /* __________ Load More __________ */
  #load_more_button {
    background-color: #fff;
  }

  /* __________ Deeplinks Mobile __________ */
  .deeplinks_mobile {
    display: block;
  }

  .deeplinks_mobile__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: scroll;
    touch-action: manipulation;
  }

  .deeplinks_mobile__link {
    border-radius: 1em;
    border: 1px solid #fff;
    flex: 0 0 auto;
    font-size: 12px;
    font-weight: bold;
    margin: 0 1em 0.5em 0;
    padding: 0 0.75em;
  }

  /* __________ Site Description__________ */
  .site_description {
    display: none;
  }

  /* __________ Social Links __________ */
  .social_links {
    display: none;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	SINGLE PRODUCT PAGE
/*-----------------------------------------------------------------------------------*/
  .brand.product_promotion {
    padding: 30px 0px;
  }
  .price.product_promotion {
    padding-bottom: 30px;
  }

  .sharing ul {
    display: inline-block;
  }

  .panel-product-promotion {
    max-width: 100%;
  }
  #panel-hr {
    max-width: 100%;
  }
  .panel-product-promotion.links a {
    line-height: 34px;
  }

  .product_promotion .shop_block {
    padding-top: 0px;
  }
  .product_promotion .shop_block hr {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .product_promotion .article_img {
    height: 167px;
    width: 131px;
  }
  .product_promotion .article_img_wrapper img {
    max-height: 167px;
    max-width: 131px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	MENU
/*-----------------------------------------------------------------------------------*/
  .menu_block {
    height: auto;
  }

  .menu_toggler {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 26px;
    color: #333;
  }
  .no-touch .menu_toggler:hover {
    color: #242424;
  }

  .navmenu {
    float: none;
    margin: 0 -15px;
    padding: 20px 15px;
  }
  .navmenu li {
    display: block;
    margin: 0;
    text-align: left;
  }
  .no-touch .navmenu li.sub-menu:hover {
    background: none !important;
  }
  .navmenu li.sub-menu:before {
    display: none !important;
  }
  .navmenu li a {
    padding: 10px 0 0 0;
    margin: 0 !important;
  }
  .no-touch .navmenu li:hover a,
  .navmenu li.active a {
    border: 0 !important;
    color: #242424;
  }
  .navmenu li a:before,
  .navmenu li a:after {
    display: none !important;
  }

  .no-touch .navmenu li.sale_menu:hover a,
  .navmenu li.sale_menu.active a {
    background: none;
    color: #333;
  }

  /* Submenu 1 lvl */
  .navmenu li.sub-menu ul {
    display: none;
    position: static;
    border: 0;
    padding: 5px 0;
  }
  .no-touch .navmenu li.sub-menu:hover ul {
    display: block;
  }
  .navmenu li.sub-menu ul li a {
    padding-bottom: 0;
  }
  .navmenu li.sub-menu ul li a:before {
    display: block !important;
  }

  /* MegaMenu */
  .navmenu li.sub-menu ul.megamenu_col1,
  .navmenu li.sub-menu ul.megamenu_col2,
  .navmenu li.sub-menu ul.megamenu_col3 {
    width: 100%;
  }
  .navmenu li ul.mega_menu li.col {
    width: 33%;
  }

  .love_list .love_list_btn p,
  .shopping_bag .shopping_bag_btn p {
    display: none;
  }
  .shopping_bag .shopping_bag_btn,
  .love_list .love_list_btn {
    padding: 20px 10px;
  }
  .cart {
    left: auto;
    right: 0;
  }

  /* __________ Top Info __________ */
  .secondary_menu li {
    padding-right: 5px;
    margin-right: 6px;
  }
  .secondary_menu li a {
    font-size: 10px;
  }

  /* __________ Top Search Form __________ */
  .top_search_form {
    float: left;
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SLIDER
/*-----------------------------------------------------------------------------------*/

  /* __________ Tovar Item __________ */
  .tovar_item_btns a {
    margin: 0 0 0 2px;
  }
  .tovar_view {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
  }

  /* __________ Brands Carousel __________ */
  .brands_carousel .jcarousel {
    width: 362px;
  }
  .brands_carousel .jcarousel li {
    width: 114px;
    margin-right: 10px;
  }

  /* __________ Recent Posts __________ */
  .recent_post_img {
    width: 200px;
    margin-right: 15px;
  }
  .recent_post_title {
    font-size: 18px;
    line-height: 22px;
  }

  /* __________ Services __________ */
  .service_item i {
    width: 28px;
    font-size: 30px;
  }
  .service_item p {
    font-size: 14px;
  }
  .service_item span {
    font-size: 11px;
    line-height: 17px;
  }

  .service_item.type2 p {
    font-size: 20px;
    line-height: 20px;
  }
  .service_item.type2 span {
    padding: 0;
  }

  /* __________ Banners __________ */
  .home_teaser_description {
    padding: 40px 15px 25px;
  }
  .home_teaser_description p {
    line-height: 35px;
    font-size: 35px;
  }
  .home_teaser_description span {
    line-height: 15px;
    font-size: 15px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME MEN PROMO
/*-----------------------------------------------------------------------------------*/

  /* __________ Banners __________ */
  .top_sale {
    min-height: 500px;
  }

  .top_sale_description {
    padding: 170px 0 50px;
  }
  .top_sale_description p {
    font-size: 60px;
    line-height: 60px;
  }
  .top_sale_description span {
    font-size: 24px;
    line-height: 28px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME KIDS
/*-----------------------------------------------------------------------------------*/
  .school_sale {
    min-height: 300px;
    display: none;
  }

  .school_sale_description {
    padding: 140px 0 50px;
    width: 300px;
  }
  .school_sale_description p {
    font-size: 40px;
    line-height: 40px;
  }
  .school_sale_description span {
    font-size: 14px;
    line-height: 20px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME VIDEO
/*-----------------------------------------------------------------------------------*/
  .fashion_week {
    min-height: 450px;
  }

  .fashion_week_description p {
    font-size: 40px;
    line-height: 40px;
  }
  .fashion_week_description span {
    font-size: 14px;
    line-height: 20px;
  }

  .fashion_week_description {
    padding: 140px 0 30px;
  }
  .video_wrapper {
    width: 400px;
    height: 196px;
  }
  .video_wrapper iframe {
    height: 272px;
  }

  /* __________ Best Products Carousel __________ */
  .best_products .jcarousel li {
    width: 220px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SALE
/*-----------------------------------------------------------------------------------*/
  .sale_caption1 .title1 {
    font-size: 60px;
    line-height: 60px;
  }
  .sale_caption1 .title2 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 25px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	HOME SHOES
/*-----------------------------------------------------------------------------------*/
  .shoes_page {
    min-height: 460px;
  }
  .shoes_page_description {
    padding: 140px 0 30px;
  }
  .shoes_page_description p {
    font-size: 40px;
    line-height: 40px;
  }
  .shoes_page_description p b {
    font-size: 45px;
    line-height: 40px;
  }
  .shoes_page_description span {
    font-size: 24px;
    line-height: 24px;
    padding-bottom: 30px;
  }

  /* __________ Popular Articles carousel __________ */
  .popular_articles .jcarousel li {
    width: 217px;
    margin-right: 15px;
  }
  .article_title {
    font-size: 18px;
    line-height: 20px;
  }
  .post_meta li {
    font-size: 10px;
    padding-right: 10px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	SHOP
/*-----------------------------------------------------------------------------------*/
  .breadcrumb.women,
  .breadcrumb.men {
    padding: 120px 0 40px;
  }
  .breadcrumb h2 {
    font-size: 90px;
    line-height: 90px;
  }

  /* __________ Sidebar __________ */
  #price-range input {
    width: 50%;
  }
  #sidebar {
    display: none;
  }

  .tovar_title {
    font-size: 12px;
    width: 73%;
  }

  .widget_banners a {
    display: inline-block;
    width: 143px;
    margin-right: 5px;
  }
  .widget_banners a:last-child {
    margin-right: 0;
  }

  /* __________ Sorting Options __________ */
  .sorting_top {
    display: none;
  }
  .no-touch .filter_button:hover {
    transition: none;
  }

  /* __________ New Arrivals __________ */
  .new_arrivals .jcarousel li {
    width: 130px;
  }

  /* __________ Tovar Item Sizes __________ */
  .product_sort {
    width: 53%;
  }

  .shop_block.variable-sizes .tovar_wrapper {
    width: 100%;
    padding-right: 0;
  }
  .shop_block.variable-sizes .tovar_img {
    width: 160px;
    text-align: left;
  }
  .shop_block.variable-sizes .tovar_title {
    width: 100%;
  }
  .shop_block.variable-sizes .tovar_description {
    width: 304px;
  }
  .shop_block.variable-sizes .tovar_item_btns {
    width: 180px;
  }
  .variable-sizes .tovar_img_wrapper {
    height: 240px !important;
  }

  .tovar_description {
    min-height: 80px;
  }

  .shop .top_sale_banners .banner {
    width: 100%;
  }

  /* __________ Product Details __________ */
  .open-project-link {
    display: none !important;
  }

  .tovar_view_fotos {
    float: none;
    width: 450px;
    margin: 0 auto 30px;
  }
  .tovar_view_description {
    float: none;
    width: 100%;
  }

  /* __________ Modal __________ */
  #tovar_content {
    width: 750px;
  }

  /* __________ Love List __________ */
  table tr.cart_item td.product-thumbnail {
    width: 70px;
  }
  table tr.cart_item td.product-thumbnail a img {
    width: 60px;
  }
  table tr.cart_item td.product-name a {
    display: block;
    font-size: 11px;
    line-height: 15px;
  }
  table tr.cart_item td.product-price {
    width: 80px;
  }
  table tr.cart_item td.product-add-bag .add_bag span {
    display: none;
  }
  table tr.cart_item td.product-add-bag .add_bag i {
    padding-right: 0;
  }
  table tr.cart_item td {
    padding-right: 10px;
  }
  table tr.cart_item td.product-name {
    width: 200px;
  }
  table tr.cart_item td.product-add-bag {
    width: 50px;
  }
  table tr.cart_item td.product-remove span {
    display: none;
  }

  /* __________ Shopping Bag __________ */
  .widget_bag_totals .btn {
    padding-left: 5px;
    padding-right: 5px;
  }

  /* __________ Product Catalog __________ */
  .product_catalog_item img {
    width: 100px;
    margin-right: 10px;
  }
  .product_catalog_title {
    font-size: 20px;
    line-height: 22px;
  }
  .product_catalog_item .product_catalog_list {
    padding-left: 100px;
  }
  .product_catalog_list li {
    padding-top: 0;
  }
  .product_catalog_list li:before {
    top: 1px;
  }

  /* __________ Brands List __________ */
  .brand_letter {
    font-size: 24px;
    line-height: 2;
    margin-right: 10px;
    width: 55px;
  }
  .brands_list_block .product_catalog_list li {
    font-size: 14px;
    padding-top: 0;
  }
  .brands_list_block .product_catalog_list li:before {
    top: 4px;
  }
  #brands_jump_to {
    font-size: 26px;
    padding-right: 45px;
    padding-top: 26px;
  }

  /* __________ Product __________ */
  .no-touch .article:hover {
    border: 0;
    margin: 0px 0px 50px 0px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	ABOUT PAGE
/*-----------------------------------------------------------------------------------*/
  .about_us_description p {
    font-size: 18px;
    line-height: 22px;
  }
  .about_us_description span {
    font-size: 11px;
    line-height: 17px;
    margin-bottom: 10px;
  }

  /* __________ Breadcrumbs __________ */
  .breadcrumb {
    border-bottom-width: 30px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	ARTICLES
/*-----------------------------------------------------------------------------------*/
  .article_large .article_image {
    width: 280px;
    margin: 0 10px 5px 0;
  }
  .article_large .article_title {
    line-height: 20px;
    font-size: 16px;
  }
  .article_small .article_title {
    font-size: 14px;
    line-height: 20px;
  }

  /* __________ Single Article __________ */
  .article_single .article_image {
    width: 370px;
    padding-left: 10px;
  }
  .article_single_caption {
    width: 109px;
    height: 146px;
    padding: 0 8px;
  }
  .article_single_caption p {
    font-size: 12px;
    line-height: 17px;
  }
  .article_single_content h3 {
    float: none;
    width: 100%;
    font-size: 17px;
    line-height: 22px;
  }
  .article_single_content blockquote {
    width: 74%;
    float: none;
  }

  .flexslider.article_slider {
    width: 280px;
    margin: 0 20px 10px 0;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	SHORTCODES
/*-----------------------------------------------------------------------------------*/
  .shortcodes_buttons .btn {
    margin-bottom: 10px;
  }

  .price_item .btn {
    width: 150px;
  }
  .price_item_text {
    padding: 7px 5px 5px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	FOOTER
/*-----------------------------------------------------------------------------------*/
  footer .newsletter_btn {
    width: 20%;
  }
  footer .newsletter_form input[type="text"] {
    width: 78%;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	PAGINATION TOP
/*-----------------------------------------------------------------------------------*/
  .sorting_top ul.pagination {
    display: none;
  }
}

@media (max-width: 479px) {
  .article_img {
    height: 200px;
  }

  .top_search_btn {
    padding: 13px;
  }
  .shopping_bag .shopping_bag_btn,
  .love_list .love_list_btn {
    padding: 13px 5px;
  }
  .love_list,
  .shopping_bag {
    position: static;
  }
  .menu_block {
    position: relative;
  }
  .menu_block div.container:nth-child(1) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .cart {
    left: 0;
    right: 0;
    width: 100%;
  }

  .alert .close {
    top: 2px;
    right: 10px;
  }

  .navmenu li ul.mega_menu li.col {
    width: 100%;
  }

  .services_section_description p {
    font-size: 24px;
    line-height: 26px;
  }

  .recent_post_img {
    width: 160px;
  }

  .top_sale_description {
    padding: 130px 0 30px;
  }
  .top_sale_description p {
    font-size: 30px;
    line-height: 30px;
  }
  .top_sale_description span {
    font-size: 16px;
    line-height: 20px;
  }

  .video_wrapper {
    width: 290px;
    height: 143px;
  }

  .best_products .jcarousel li {
    width: 290px;
  }

  .sale_caption1 {
    top: 70%;
  }
  .sale_caption1 .title1 {
    font-size: 30px;
    line-height: 30px;
  }
  .sale_caption1 .title2 {
    font-size: 14px;
    line-height: 20px;
  }

  .breadcrumb h2 {
    font-size: 40px;
    line-height: 40px;
  }

  .widget_banners a {
    width: 91px;
  }

  .pagination li {
    margin-bottom: 10px;
  }

  .shop_block.variable-sizes .tovar_img {
    width: 120px;
  }
  .shop_block.variable-sizes .tovar_description {
    width: 184px;
  }
  .tovar_content {
    font-size: 10px;
    line-height: 13px;
  }

  .tovar_view_fotos {
    width: 290px;
  }
  #slider1,
  #slider2 {
    padding-right: 40px;
  }
  #carousel1,
  #carousel2 {
    width: 50px;
  }
  #carousel1 .slides li,
  #carousel2 .slides li {
    width: 50px;
    height: 75px;
  }
  .tovar_view_title {
    font-size: 24px;
    line-height: 26px;
  }
  .tovar_information .tabs li {
    font-size: 12px;
    padding-left: 13px;
    padding-right: 13px;
  }

  .my_account_block .login {
    float: none;
    width: 100%;
    padding: 10px 10px 20px;
    border-bottom: 1px solid #ccc;
  }
  .new_customers {
    width: 100% !important;
    float: none !important;
    padding: 10px;
  }

  .product_catalog_item {
    padding-bottom: 20px;
  }

  .article_single .article_image {
    width: 100%;
    float: none;
  }
  .article_single_caption {
    float: none;
    margin: 0 0 20px;
    width: 100%;
    height: 90px;
  }
  .article_single_content h3 {
    padding-left: 0;
  }
  .flexslider.article_slider {
    width: 100%;
    margin-right: 0;
    float: none;
  }

  .checkout_nav li {
    font-size: 10px;
    line-height: 13px;
    min-height: 66px;
  }
  .checkout_delivery li {
    width: 100%;
    margin: 0 0 20px;
  }
  .credit_card_number {
    width: 100%;
    float: none;
    padding-bottom: 40px !important;
  }
  .payment_method {
    width: 100%;
    float: none;
  }
  .credit_card_number_form div.fancy-select div.trigger {
    width: 104px;
  }

  #accordion h4.accordion_title {
    font-size: 16px;
    line-height: 20px;
    padding: 13px 0 13px 40px;
  }
  #accordion h4.accordion_title:before {
    width: 25px;
    height: 25px;
    font-size: 16px;
    line-height: 21px;
  }
  #accordion .accordion_content {
    font-size: 11px;
    line-height: 17px;
    padding-left: 40px;
  }
  #accordion .accordion_content p {
    margin-bottom: 15px;
  }

  .post .post_image {
    width: 150px;
    margin: 0 10px 5px 0;
  }
  .blog_post_content {
    padding-top: 10px;
  }
  .blog_post_content h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .blog_post_content h2 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .blog_post_content p {
    font-size: 11px;
    line-height: 17px;
  }
  blockquote:before {
    font-size: 30px;
    left: -50px;
  }
  blockquote {
    margin-left: 50px;
    font-size: 11px !important;
    line-height: 17px !important;
  }
  #comments .avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .comment_right {
    padding: 10px;
    font-size: 10px;
    line-height: 15px;
  }
  #comments ol li ul li {
    padding-left: 30px;
  }
  #comment_form input[type="text"] {
    width: 100%;
  }
  #comment_form .comment_note {
    width: 100%;
    display: block;
    padding-bottom: 20px;
  }

  .brands_carousel .jcarousel {
    left: 24px;
    width: 242px;
  }

  footer .newsletter_form input[type="text"] {
    width: 65%;
  }
  footer .newsletter_btn {
    width: 31%;
  }

  .social a {
    width: 36px;
    height: 36px;
    line-height: 32px;
  }

  /*-----------------------------------------------------------------------------------*/
  /*	PAGINATION TOP
/*-----------------------------------------------------------------------------------*/
  .sorting_top ul.pagination {
    display: none;
  }

  /* __________ Bootstrap Style __________ */
  .col-ss-1,
  .col-ss-2,
  .col-ss-3,
  .col-ss-4,
  .col-ss-5,
  .col-ss-6,
  .col-ss-7,
  .col-ss-8,
  .col-ss-9,
  .col-ss-10,
  .col-ss-11 {
    float: left;
  }
  .col-ss-12 {
    width: 100%;
  }
  .col-ss-11 {
    width: 91.66666666666666%;
  }
  .col-ss-10 {
    width: 83.33333333333334%;
  }
  .col-ss-9 {
    width: 75%;
  }
  .col-ss-8 {
    width: 66.66666666666666%;
  }
  .col-ss-7 {
    width: 58.333333333333336%;
  }
  .col-ss-6 {
    width: 50%;
  }
  .col-ss-5 {
    width: 41.66666666666667%;
  }
  .col-ss-4 {
    width: 33.33333333333333%;
  }
  .col-ss-3 {
    width: 25%;
  }
  .col-ss-2 {
    width: 16.666666666666664%;
  }
  .col-ss-1 {
    width: 8.333333333333332%;
  }
}

/* __________ Special Media Queries __________ */
@media (max-width: 330px) {
  .recommended_link a {
    font-size: 12px;
  }
}

@media (min-width: 520px) and (max-width: 1199px) {
  .menu_toggler {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
