footer,
header,
main,
nav,
section {
  display: block;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}
b,
strong {
  font-weight: bold;
}
hr {
  height: 0;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
code {
  font-family: monospace, serif;
  font-size: 1em;
}
small {
  font-size: 80%;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
select {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="checkbox"] {
  padding: 0;
  box-sizing: border-box;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
@media print {
  * {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  a[href^="#"]:after {
    content: "";
  }
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 2cm 0.5cm;
  }
  p,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h3 {
    page-break-after: avoid;
  }
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333;
  background-color: #fff;
}
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  background-image: none;
}
a {
  color: #428bca;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #2a6496;
  text-decoration: underline;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
p {
  margin: 0 0 10px;
}
small {
  font-size: 85%;
}
h1,
h3,
h4 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
}
h1,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h1 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul {
  margin-bottom: 0;
}
code {
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  white-space: nowrap;
  background-color: #f9f2f4;
  border-radius: 4px;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container:before,
.container:after {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}
.container:before,
.container:after {
  display: table;
  content: " ";
}
.container:after {
  clear: both;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row:before,
.row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}
.row:before,
.row:after {
  display: table;
  content: " ";
}
.row:after {
  clear: both;
}
.col-xs-6,
.col-xs-12,
.col-sm-6,
.col-sm-12,
.col-md-4,
.col-md-12,
.col-lg-4,
.col-lg-9,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-6 {
  float: left;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-12 {
  width: 100%;
}
@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
  .col-sm-6 {
    float: left;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
  .col-md-4 {
    float: left;
  }
  .col-md-4 {
    width: 33.33333333333333%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .col-lg-4,
  .col-lg-9 {
    float: left;
  }
  .col-lg-4 {
    width: 33.33333333333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}
.clearfix:after {
  clear: both;
}
@-ms-viewport {
  width: device-width;
}
@media screen and (max-width: 400px) {
  @-ms-viewport {
    width: 320px;
  }
}
