mobile-menu {
  -webkit-user-select: none;
  background-color: #fff;
  contain: strict;
  display: flex;
  flex-flow: column;
  height: 100%;
  left: 0;
  max-width: 500px;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  transition: 0.3s ease-in-out;
  user-select: none;
  width: 100%;
  will-change: transform;
  z-index: 6;

  &[open="true"] {
    transform: translate3d(0, 0, 0);
  }
}
.mobile_menu__headline_container {
  align-items: center;
  display: flex;
  flex: 0 0 51px;
  font-size: 18px;
  font-weight: 900;
  justify-content: flex-end;
  padding: 5px 15px;
  text-align: center;
}
.mobile_menu__headline_text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  flex: 1 1 auto;
  user-select: none;
}
.mobile_menu__headline_svg {
  border: 10px solid transparent;
  box-sizing: content-box;
  flex: 0 1 20px;
  height: 20px;
  pointer-events: auto;
  stroke: #666;
  width: 20px;

  & * {
    pointer-events: none;
  }

  &--close {
    position: absolute;
    right: 5px;
  }
}
.mobile_menu__content_container {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.navigation {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}
.navigation__vertical {
  flex: 0 0 auto;
  height: 0;
  padding-top: 25%;
  position: relative;
  width: 100%;
}
.navigation__vertical_selector {
  bottom: 10%;
  color: white;
  display: flex;
  font-size: 22px;
  font-weight: 900;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.navigation__vertical_selector_item {
  -webkit-user-select: none;
  border: 10px solid transparent;
  cursor: pointer;
  position: relative;
  user-select: none;

  &:after {
    background-color: #f9564e;
    bottom: -3px;
    content: "";
    height: 2px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  mobile-menu[active-vertical="female"] &[data-vertical="female"]:after {
    opacity: 1;
  }

  mobile-menu[active-vertical="male"] &[data-vertical="male"]:after {
    opacity: 1;
  }
}
.navigation__vertical_item {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: -1;

  &:after {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.17) 40%,
      #000000ab 100%
    );
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &[data-vertical="female"] {
    transform: translate3d(-100%, 0, 0);

    mobile-menu[active-vertical="female"] & {
      transform: translate3d(0, 0, 0);
    }
  }

  &[data-vertical="male"] {
    transform: translate3d(100%, 0, 0);

    mobile-menu[active-vertical="male"] & {
      transform: translate3d(0, 0, 0);
    }
  }
}
.navigation__vertical_item_img {
  height: 100%;
  width: 100%;
}
.navigation__headline {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 15px;
  position: relative;
}
.navigation__headline_action {
  align-items: center;
  border: 10px solid transparent;
  border-left: none;
  display: flex;
  left: 5px;
  opacity: 0;
  position: absolute;
  transition: 0.3s ease-in-out;

  &--visible {
    opacity: 1;
  }
}
.navigation__headline_icon {
  box-sizing: content-box;
  height: 30px;
  position: relative;
  stroke: #fff;
  width: 30px;
}
.navigation__headline_text {
  font-size: 16px;
  font-weight: 900;
}
.navigation__category {
  background-color: rgba(0, 0, 0, 0.6);
  contain: layout;
  flex: 1 0 632px;
  position: relative;
}
.navigation__category_container {
  contain: strict;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  transition: 0.3s ease-in-out;
  width: 100%;
  will-change: transform;

  &[data-vertical="female"] {
    transform: translate3d(-100%, 0, 0);

    mobile-menu[active-vertical="female"] &[data-active="false"] {
      transform: translate3d(0, 0, 0);
    }
  }

  &[data-vertical="male"] {
    transform: translate3d(100%, 0, 0);

    mobile-menu[active-vertical="male"] &[data-active="false"] {
      transform: translate3d(0, 0, 0);
    }

    mobile-menu[active-vertical="male"] &[data-active="true"] {
      transform: translate3d(-100%, 0, 0);
    }
  }

  &--sub-container {
    transform: translate3d(100%, 0, 0);
    visibility: hidden;

    &[data-active="true"] {
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
}
.navigation__inner_category_container {
  contain: layout;
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
}
.navigation__category_item {
  align-items: center;
  border-radius: 4px;
  border: 1px solid white;
  display: flex;
  flex-flow: column;
  flex: 0 1 calc(33.3% - 20px);
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
.navigation__category_item_icon {
  flex: 0 0 103px;

  &--show-all {
    height: 100%;
    padding: 12px;
    width: 100%;
  }
}
.navigation__category_item_text {
  -webkit-hyphens: auto;
  -webkit-user-select: none;
  color: white;
  flex: 0 0 33px;
  font-size: 14px;
  hyphens: auto;
  line-height: 1;
  padding: 0px 5px 9px;
  text-align: center;
  user-select: none;
  word-break: break-word;
}
.link_container {
  display: flex;
  flex: 0 0 auto;
  font-size: 12px;
}
.link_container__link {
  align-items: center;
  color: #666;
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  line-height: 3;

  &:nth-child(2) {
    border-left: 1px solid #666;
    border-right: 1px solid #666;
  }
}
.link_container__link_icon {
  flex: 0 0 24px;
  padding: 0 2px;
}
.link_container__link_text {
  flex: 0 1 auto;
}
