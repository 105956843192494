.quick-link {
    background-color: rgba(0, 0, 0, 0.85);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: all 0.25s ease-in-out;
    transform: translateX(100%);
    opacity: 0;
    padding: 30px 15px;

    &.active {
        transform: translateX(0);
        opacity: 1;
    }
}

@media screen and (min-width: 600px) {
    .quick-link {
        display: none;
    }
}

.quick-link__wrapper {
    border-radius: 8px;
    border: 1px solid #595959;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #fff;

    &::before {
        content: "Sponsored";
        color: #fff;
        font-size: 9px;
        font-weight: 600;
        left: 0;
        position: relative;
        top: -20px;
    }
}

.quick-link__close__icon {
    border-radius: 100%;
    border: 1px solid white;
    box-sizing: content-box;
    color: white;
    cursor: pointer;
    display: block;
    height: 16px;
    margin-left: auto;
    margin-right: 0;
    padding: 2px;
    position: absolute;
    right: 0;
    stroke: currentColor;
    top: -26px;
    width: 16px;

    & * {
        pointer-events: none;
    }
}

.quick-link__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.quick-link__logo {
    width: 160px;
    height: 60px;
    object-fit: contain;
}

.quick-link__body__content__title {
    margin-bottom: 8px;
}

.quick-link__body__content__title__text {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 10px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        background-color: #595959;
        height: 1px;
    }
}

.quick-link__body__content__tiles {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100dvh - 180px);
    overflow-y: auto;
}

.quick-link__body__content__tiles__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.quick-link__body__content__tiles__tile__link {
    display: block;
    height: 100%;
    padding: 8px;
    width: 100%;
}

.quick-link__body__content__tiles__tile__link__image {
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid #595959;
    object-fit: contain;
    object-position: center;
    width: 100%;
}

.quick-link__body__content__tiles__tile__link__text {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 2px;
    text-align: center;
}