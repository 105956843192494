.filter__container {
    display: none;
    flex-wrap: wrap;
    margin: 6px 0px 12px;
    user-select: none;
    z-index: 2;
  
    @media screen and (min-width: 1199px) {
      display: flex;
    }
  }

.filter__more-filters {
	align-items: center;
	color: #9f9f9f;
	cursor: pointer;
	display: flex;
	margin-bottom: 14px;

  &:hover {
    color: #595959;
  }

  &--hidden {
    display: none;
  }
}

.filter__more-filters-text {
	font-size: 14px;
	font-weight: 700;
	user-select: none;
}

.filter__more-filters-icon {
	height: 20px;
	transform: rotateZ(90deg);
	width: 26px;
}