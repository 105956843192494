.hero_search__container {
    align-items: center;
    background: linear-gradient(260deg, #ff9387 0%, #febba0 100%);
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: relative;
    padding: 18vw 0;

    @media screen and (orientation: landscape) {
        padding: 10vw 0;
    }
}
.hero_search__background {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    opacity: 0.2;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%;
}
.hero_search__headline {
	color: white;
	font-family: "Lobster";
	font-size: 12vw;
	margin-bottom: 12vw;
    z-index: 1;

    @media screen and (orientation: landscape) {
        font-size: 5vw;
        margin-bottom: 5vw;
    }
}
.hero_search__headline_text {
    display: block;
	line-height: 1;
}
.hero_search__form {
    border-radius: 5vw;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    z-index: 1;
}
.hero_search__search_container {
	align-items: center;
	display: flex;
    z-index: 1;
}
.hero_search__search_input {
    background-color: #fff;
    border-bottom-right-radius: 5vw;
    border-top-right-radius: 5vw;
    border: none;
    font-size: 4vw;
    font-weight: 700;
    height: 10vw;
    padding-left: 2vw;
    width: 68vw;

    @media screen and (orientation: landscape) {
        font-size: 1.5vw;
        height: 3vw;
        padding-left: 1vw;
        width: 35vw;
    }

    @media screen and (orientation: landscape) and (max-width: 1199px) {
        font-size: 2.3vw;
        height: 5vw;
        padding-left: 1vw;
        width: 60vw;
    }

    &:focus {
        border: none;
    }
}
.hero_search__search_input_btn {
    align-items: center;
    background-color: #f8554e;
    border-bottom-left-radius: 5vw;
    border-top-left-radius: 5vw;
    border: none;
    display: flex;
    height: 10vw;
    justify-content: center;
    width: 12vw;

    @media screen and (orientation: landscape) {
        height: 3vw;
        width: 4vw;
    }

    @media screen and (orientation: landscape) and (max-width: 1199px) {
        height: 5vw;
        width: 6vw;
    }
}
.hero_search__search_input_btn_icon {
    color: white;
    height: 6vw;
    stroke: currentColor;
    width: 6vw;

    @media screen and (orientation: landscape) {
        height: 2vw;
        width: 2vw;
    }
    
    & > * {
        stroke-width: 6px;
    }
}