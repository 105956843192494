.coupon-modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .65);
    justify-content: center;
    align-items: center;

    &--active {
        display: flex;
    }
}

.coupon-modal__content {
    width: 100%;
    max-width: 650px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-height: 100%;
    overflow-y: auto;
}

.coupon-modal__close {
    margin-right: -10px;
    margin-top: -20px;
}

.coupon-modal__close__icon {
    border: 10px solid transparent;
    box-sizing: content-box;
    cursor: pointer;
    display: block;
    height: 20px;
    margin-left: auto;
    margin-right: 0;
    stroke: currentColor;
    width: 20px;
}

.coupon-modal__content__header__headline {
    width: calc(100% + 40px);
    display: flex;
    gap: 20px;
    padding: 20px;
    background-color: #e6e6e6;
    position: relative;
    left: -20px;
    right: -20px;
    border-top: 1px solid #cecece;
}

.coupon-modal__content__header__headline__image {
    width: 60px;
    height: 60px;
    object-fit: contain;
    border-radius: 4px;
    padding: 4px;
    background-color: white;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.coupon-modal__content__header__headline__text {
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
}

.coupon-modal__content__body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 40px;
}

.coupon-modal__content__code {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.coupon-modal__content__code__text {
    border-radius: 4px;
    border: 1px dashed #e40822;
    color: #333;
    flex: 0 0 auto;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    min-width: 140px;
    text-align: center;
    padding: 10px 20px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.coupon-modal__content__code__btn {
    align-items: center;
    background-color: #e40822;
    border-radius: 4px;
    border: 1px solid #e40822;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    line-height: 1.25;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;
    flex: 0 0 auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    gap: 5px;
    transition: all .2s ease-in-out;

    &--copied {
        background-color: #a20619;
        border-color: #a20619;
    }
}

.coupon-modal__content__code__btn__icon {
    height: 20px;
    width: 20px;
    stroke: currentColor;
    stroke-width: 2;
    display: none;

    &--visible {
        display: block;
    }
}

.coupon-modal__content__deal {
    display: flex;
    justify-content: center;
}

.coupon-modal__content__deal__text {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #333;
}

.coupon-modal__content__redirect {
    text-align: center;

    &--deal {
        margin-top: -10px;
    }
}

.coupon-modal__content__redirect__link {
    display: inline-block;

    .coupon-modal__content__redirect--deal & {
        padding: 14px 54px;
        border-radius: 4px;
        background-color: #e40822;
    }
}

.coupon-modal__content__redirect__link__text {
    color: #e40822;
    margin: 0;

    .coupon-modal__content__redirect--deal & {
        color: #fff;
    }
}

.coupon-modal__feedback {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 0;
}

.coupon-modal__feedback__text {
    margin: 0;
}

.coupon-modal__feedback__btns {
    display: flex;
    gap: 12px;
}

.coupon-modal__feedback__btn {
    background: none;
    border: none;
    outline: none;
    display: none;

    &--visible {
        display: block;
    }
}

.coupon-modal__feedback__btn__icon {
    height: 28px;
    width: 28px;
    border: 2px solid transparent;
    box-sizing: content-box;
    fill: #666;
    pointer-events: none;
}

.coupon-modal__feedback__btn__text {
    color: #666;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25;
    margin: 0;
    text-transform: uppercase;
    display: none;

    &--visible {
        display: block;
    }
}


.coupon-modal__description {
    display: flex;
    justify-content: space-between;
    position: relative;

    &::before {
        background-color: #cecece;
        content: '';
        height: 1px;
        position: absolute;
        top: -15px;
        left: -20px;
        right: -20px;
    }
}

.coupon-modal__description__text {
    margin: 0;
}

.coupon-modal__carrot {
    background-color: #666;
    bottom: 1px;
    display: inline-block;
    height: 6px;
    margin-left: 2px;
    position: relative;
    width: 8px;

    &--down {
        clip-path: polygon(0 0, 50% 100%, 100% 0);
    }

    &--up {
        clip-path: polygon(0 100%, 50% 0, 100% 100%);
    }
}

.coupon-modal__details {
    display: none;

    &--open {
        display: flex;
        flex-flow: column;
    }
}

.coupon-modal__details li {
    list-style-position: inside;
    list-style-type: disc;
    margin: 0;
    padding: 0;
    max-width: 80ch;

    &:not(:last-child) {
        margin-bottom: 4px;
    }

    & strong {
        font-weight: 700;
    }

    &:has(strong) {
        list-style: none;
    }
}